import jwtDecode from "jwt-decode";


interface IDecoded {
    brandId: string,
    exp: number,
    iat: number,
    role: string,
    sub: string
}

export function isExpiredJWT(accessToken: string) {
    const decoded: IDecoded = jwtDecode(accessToken);

    var timeNowSeconds = Math.floor(new Date().getTime() / 1000);
    var expiryTime = decoded.exp;

    if (expiryTime <= timeNowSeconds) {
        return true;
    } else {
        return false;
    }
}