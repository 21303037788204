import { CSSProperties, ReactNode } from "react";
import { FaUserAlt } from "react-icons/fa";
import { WiTime9 } from "react-icons/wi";
import { HiIdentification } from "react-icons/hi";
import styles from './identification_card.module.scss'
type Informations = {
  credential: string;
  nick: string;
  timestamp?: string | Date;
  memberType?: string;
};

interface CardIndetificationProps {
  style?: CSSProperties;
  className?:any;
  logo?:string;
  color?:string;
  informations: Informations;
  children?: ReactNode;
}


export function CardIdentification({
  style,
  informations,
  children,
  logo,
  color,
  className
}: CardIndetificationProps) {

    function handleNewName(name:string){
      let newName = ''
      let count = 0
      for(let i = 0; i <  name?.length; i++){
        newName = newName + name[i]
        if(name[i] == ' '){
            count = count + 1
        }
        if(count == 5){
          name = newName
          return name
        }
      }
      return name
    }

  return (
    <div style={style} className={className}>
      <div className={styles.Content}>
        {logo? 
          <img src ={logo}/>
          : 
          ""
        }
        <div>
          <div>
            {!logo &&<FaUserAlt size={40} color="#272727" />}
            <div style={{display:'flex', flexDirection:"column", marginLeft:'1rem'}}>
              <h1 style={{color:style?.color}}>
              {!logo?handleNewName(informations?.credential):informations?.credential} 
              </h1>
              <div>
                <div>
                  <HiIdentification size={18} color={color || "#acacac"} />
                  <h6 style={{color:color}}>{informations?.nick}</h6>
                </div>
                {
                  informations.timestamp &&
                  <div> 
                    <WiTime9 color={color || "#acacac"} />
                    <h6 style={{color:color}}>{informations.timestamp}</h6>
                  </div>
                } 
                </div>
              </div>
            </div>  
          <div className={styles.behavior_container}>
             {children}
          </div>
         
        </div>
      </div>
      
     
    </div>
  );
}
