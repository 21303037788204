
import jwtDecode from "jwt-decode";
import { useContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { isExpiredJWT } from "../../utils/verifyJWT";
import api from "../../services/axios";
import { routeManagement } from "../../services/sessionService";


export function PrivateRoute() {
  const navigate = useNavigate();
  const { user, userBrand, setUserBrand, Logout,getSession,setBrand } = useContext(AuthContext);
  const sessionUser = JSON.parse(
    localStorage.getItem("accessTokenPortunus") || "{}"
  );
  if (userBrand?.accessToken) {
    if (isExpiredJWT(userBrand.accessToken)) {
      Logout(); 
    }
  }
  useEffect(() => {
    getSession().then((session: any) => {
    });
    setUserBrand(sessionUser);
  }, []);
  
  return  userBrand || sessionUser.accessToken ? <Outlet /> : <Navigate to={"/"} />;
}



