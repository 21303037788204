import style from "./gaussStyles.module.scss";
import {useEffect} from 'react'
interface Props{
    min:number;
    mean:number;
    max:number;
    sigma:number;
}

let minStyle={
    left: "50%",
}

let meanStyle={
    left: "50%",
}
let maxStyle={
    left: "50%",
}

export function GaussIndicator({min,mean,max, sigma}:Props){
    /*
            (xi - md)
        z = ---------
              sigma 
    */
    function Standardize(){
        let scale = 5.4
        let calcZ1 = (min - mean)/ sigma
        let calcZ2 =(max-mean)/sigma
        let z1: string|number;
        let z2:string|number;
        if(calcZ1 >= -2){
            z1 = 50+(calcZ1*scale)
            z2 = 50 + (calcZ2*scale)
            z1 = `${z1}%`
            z2 = `${z2}%`
        }else{
            z1 = 50+(calcZ1*10)
            z2 = 50+(calcZ2*10)
            z1 = `${z1}%`
            z2 = `${z2}%`
        }
      
    
        return {z1,z2}
    }

    return(
      <div className={style.gaussContainer}>
        <span style={{...minStyle, left:Standardize().z1}} className={style.min}>
          <label style={{fontSize:"0.7rem"}}>
          {(min / 100)?.toLocaleString("default", {
            style: "currency",
            currency: "BRL",
            maximumFractionDigits: 2,
            })}
          </label>
        </span>
        <span style={meanStyle} className={style.mean}>
          <label style={{fontSize:"0.7rem"}}>
          {(mean / 100)?.toLocaleString("default", {
            style: "currency",
            currency: "BRL",
            maximumFractionDigits: 2,
            })}
          </label>
        </span>
        <span style={{...maxStyle, left:Standardize().z2}} className={style.max}> 
          <label style={{fontSize:"0.7rem"}}>
          {(max / 100)?.toLocaleString("default", {
            style: "currency",
            currency: "BRL",
            maximumFractionDigits: 2,
            })}
          </label>
        </span>
      </div>
    )
}