import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { SearchBar } from "../../components/searchBar/searchBar";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { getnewItems } from "../../routes/getRoutes/getRoutes";
import { IclothingItem } from "../../types/clothingType";
import { IncomingContext } from "../../contexts/IncomingContext";
import { handleDateFormat } from "../../utils/handleDateFormat";
import { CardViewTest } from "../../components/cardView copy/card";
import { InformationSession } from "../../components/cardView copy/InformationSession";
import { BiLoaderAlt } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { ItemCart, PersonalContext } from "../../contexts/personalContext";
import { useNavigate } from "react-router-dom";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";

export function PersonalShopping() {
  const { userBrand, brand } = useContext(AuthContext);
  const { loadMoreFromDatabase } = useContext(IncomingContext);
  const { cart, setCart } = useContext(PersonalContext);
  const [search, setSearch] = useState<string | number>("");
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMore, setLoadingMore] = useState(false);
  const [EmptyLoadMore, setEmptyLoadMore] = useState(false);
  const [clothings, setClothings] = useState([] as any);
  const [page, setPage] = useState(2);
  const navigation = useNavigate()
  useEffect(() => {
    setIsLoading(true);
    getnewItems(userBrand, userBrand?.brandId).then((response) => {
      setClothings(response);
      setIsLoading(false);
      setEmptyLoadMore(false);
    });
  }, []);

  async function loadMore() {
    setLoadingMore(true);
    loadMoreFromDatabase(getnewItems, clothings, page, 50).then(
      (response: any) => {
        setPage(page + 1);
        if (response?.length === 0) {
          setEmptyLoadMore(true);
        }
        setLoadingMore(false);
        setClothings([...clothings, ...response]);
      }
    );
  }
  function selectItem(newItem: ItemCart) {
    let confirm = cart.filter((item) => item?.id == newItem?.id);
    if (confirm.length > 0) {
      setCart(cart.filter((item) => item?.id !== newItem?.id));
      return;
    }
    setCart([...cart, {...newItem, value:20000, quant:1}]);
  }

  function LabelPrice() {
    return <div className={style.labelPrice}>R$ 200,00</div>;
  }
  
  function Selected(props: any) {
    let confirm = cart.filter((item) => item?.id == props?.id);
    if (confirm?.length > 0) {
      return <FaCheckCircle color="#53B304" size={20} />;
    }
    return <></>;
  }
  function selected(props: any) {
    let confirm = cart.filter((item) => item?.id == props?.id);
    return confirm
  }
  function Find() {}
  function Send() {
    if(cart?.length == 0) return
    navigation("/reitailer/attendances-resume")
  }

  return (
    <div className={styles.container}>
      <HeaderRetailer />
      <div className={style.contentSearch}>
        <h4 className={styles.title}>Atendimentos</h4>
        <SearchBar
          find={Find}
          placeHolder="Pesquisar Itens"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <main className={style.container_clothings}>
        <div className={style.header_list}>
          <h2>Lista de Itens</h2>
          <button onClick={Send}>FINALIZAR {` (${cart?.length} PEÇAS)`}</button>
        </div>
        {isLoading && (
          <span className={styles.loader}>
            <BiLoaderAlt />
          </span>
        )}
        {clothings?.length > 0 && !isLoading ? (
          <div className={ " " +  style.clothing_list}>  
            {clothings?.map((clothing: any, key: number) => {
              return (
                <>
                  {clothing["clothingItems"]?.map((clothing: any) => {
                    return (
                      <div className={style.card_list}>
                        <label className={style.labelSelected}>
                          <Selected id={clothing?.id} />
                        </label>
                        <CardViewTest
                          key={clothing.id}
                          id={clothing.id}
                          CardProps={clothing}
                          imageCardAtual={0}
                          imageSize={"s400"}
                          customizeClassName={selected(clothing).length > 0? style.card_checked: ""}
                          imageAction={() => selectItem(clothing)} 
                          labels={<LabelPrice />}
                        >
                          <InformationSession CardProps={clothing}>
                            <ul>
                              <li>
                                {clothing?.refSku || clothing?.boughtSku}
                                {clothing?.skuId}
                              </li>
                            </ul>
                          </InformationSession>
                        </CardViewTest>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
        ) : (
          <div className={style.notFound}>
            {!isLoading && "Nenhuma peça encontrada"}
          </div>
        )}
      </main>
      {clothings?.length > 0 && !isLoading ? (
        <button className={style.loadMore}>Carregar Mais</button>
      ) : (
        ""
      )}
      <button className={style.buttonMobile} style={{backgroundColor:"#53B304"}} onClick={()=>navigation("/reitailer/attendances-resume")}>FINALIZAR {` (${cart?.length} PEÇAS)`}</button>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
