import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./pendding.module.scss";

export function Pendding() {
  const { user, Logout, setUser } = useContext(AuthContext);

  const handleLogout = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      await Logout();
      setUser({});
    } catch (erro) {
      console.log(erro);
      setUser({});
    }
  };
  return (
    <div className={styles.container}>
      <div>
        <h1 style={{marginBottom:'4rem'}}>APPROVAL PENDING</h1>
        <p>YOUR REQUEST HAS BEEN SENT AND WE WILL GET BACK TO YOU SHORTLY.</p>
        <button onClick={handleLogout}>BACK</button>
      </div>
    </div>
  );
}
