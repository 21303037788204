import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Toast } from '../utils/toast';
import {
  UpdateAddToHidden,
  updateColorReview,
  updateFirstTimeReview,
  updateHue,
  updateRevision,
  updateStillImage,
  updateWebReviewImage,
} from '../routes/updateRoutes/updateRoutes';
import { AuthContext } from './AuthContext';
import { IclothingItem } from '../types/clothingType';
import { IuserBrandProps } from '../types/userBrandType';
import { brandProps } from '../types/brandProps'

type IncomingProviderProps = {
  children: ReactNode;
};

interface IFirstTimeReviewClothingObject {
  id: string;
  reviewedPattern?: boolean;
  pattern?: boolean;
  lookUpMyId: number;
  trainingImage:string;
  patternPredictionImageIndex: number;
  itemOfficialImage: {s400:string}
  itemAdditionalImages: Array<{s400:string}>
}

interface IContextProps {
  loadMoreFromDatabase(requestFunction: any, data: any, page?:number, limit?:number): any;
}

export const IncomingContext = createContext({} as IContextProps);

export function IncomingContextProvider({ children }: IncomingProviderProps) {
  const [brandsData, setBrandsData] = useState<brandProps[]>([])
  const [colorId, setColorId] = useState(-2);
  const [hueId, setHueId] = useState(-1);
  const [stillImage, setStillImage] = useState(false);
  const [cropImage, setCropImage] = useState();
  const [cropImageFiles, setCropImageFiles] = useState([] as any);
  const [firstTimeReviewProductList, setFirstTimeReviewProductList] = useState<IFirstTimeReviewClothingObject[]>([]);
  const { userBrand, brand, setError, setDropMenu, setExpandNavigation, expandNavigation } = useContext(AuthContext);
  const [moreDetails, setMoreDetails] = useState();
  const [clothingIdCrop, setClothingIdcrop] = useState<IclothingItem>({}as IclothingItem);
  const [loadingUpdate, setloadingUpdate] = useState<any>();
  const [updatePageData, setUpdatePageData] = useState<any>();
  const [hueAndColorList, setHueAndColorList] = useState<any[]>([]);
  const [hiddenData, setHiddenData] = useState([]as any);
  const [firstData, setFirstData] = useState([]as any);
  const [revisionData, setRevisionData] = useState([]as any);
  const [newItemsData, setNewItemsData] = useState([]as any);
  const [hueData, sethueData] = useState([]as any);
  const [stillImageData, setStillImageData] = useState([]as any);
  const [webImageData, setWebImageData] = useState([]as any);

  async function loadMoreFromDatabase(requestFunction: any, data: any, page?:number, limit?:number) {
    const lastDateWithItems: any = data[data.length - 1]; // pegando ulitma posição do ultimo array
    const lastItem = lastDateWithItems?.clothingItems[
      lastDateWithItems.clothingItems.length - 1
    ]; // pegando ultima posição dos items de  dentro dos arrays de items

    const response = await requestFunction(userBrand, brand, '', lastItem.id, page, limit);
    return response;
  }

  function handleClickCloseNavigations(event:any){
  
    let navigation:any = document.querySelector("#button-home")
    let contentLinks:any = document.querySelector("#contentLinks")
  
    if(event?.clientX < contentLinks?.clientWidth){
      return
    }else{
      if(navigation !=null){
        if(expandNavigation){
          console.log('click')
          navigation.click()
        }
      } 
    }
     
 
  }
  return (
    <>
     <div  onClick={(event:any)=>handleClickCloseNavigations(event)}> 
      <IncomingContext.Provider
        value={{ 
          loadMoreFromDatabase,
        }}
      >
        {children}
      </IncomingContext.Provider>

    </div> 
    
    </>
     
  );
}
 