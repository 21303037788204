import style from "./PersonalAdvisor.module.scss";
import styles from "../../retailerPages/retailer/retailer.module.scss";

import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";

import {BiLoaderAlt} from 'react-icons/bi'
import logo from '../../assets/logo.png'

import { getLibs, getMatchingItems, getSimilarItems } from "../../routes/getRoutes/getRoutesRetailer";
import { getAnswersForms, getClothingItemsForSkudId, getClustering, getItemDetails, getPersonalAdvisor, getQuiz } from "../../routes/getRoutes/getRoutes";


import { ResponsiveContainer, Bar, ComposedChart, Tooltip, CartesianGrid, XAxis,Area, YAxis, Scatter, ZAxis, LabelList, Cell } from 'recharts'
import { CardIdentification } from "../../components/identification_card/IndetificationCard";
import { MessageError } from "../../components/messageError/MessageError";
import { SearchBar } from "../../components/searchBar/searchBar";
import { Toast } from '../../utils/toast'
import { CardViewTest } from "../../components/cardView copy/card";
import { InformationSession } from "../../components/cardView copy/InformationSession";
import { IoIosArrowDown, IoIosArrowUp, IoMdAddCircle } from "react-icons/io";
import { WiTime9 } from "react-icons/wi";
import { HiIdentification } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import { handleDateFormatOne } from "../../utils/handleDateFormat";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import {
  CustomizedTextYTemporal,
  CustomizedTextY,
  CustomizedTextXMonth,
  CustomTooltipElastic,
  CustomTooltip,
} 
  from './Customers'
import { GaussIndicator } from "../../components/chart/GaussIndicator"; 
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";
import { Navigation } from "../../components/navigation/Navigation";
import { IoAddSharp } from "react-icons/io5";
import ArrowLeft from '../../assets/arrowLeft.svg'
import ArrowRight from '../../assets/arrowRight.svg'
import { CardForm } from "../../components/cardForm/CardForm";
import { WidgetContext } from "../../contexts/widgetContext";
import { ModalSalesDesktop } from "../../components/modalPersonalAdvisor/modalSalesDesktop";
import { IclothingItem } from "../../types/clothingType";

export function PersonalAdvisor() {
  const { userBrand, brand ,setUserBrand, error,setError} = useContext(AuthContext);
  const {dataModal, setDataModal} = useContext(WidgetContext)
  const [reportInfo, setReportInfo] = useState<any>();
  const [loading,setLoading] = useState(false)
  const [showMoreClothing,setshowMoreClothing] = useState<string|number>(4)
  const [showMoreColor,setShowMoreColor] = useState<string|number>(4)
  const [colors,setColors]= useState<any>([])
  const [typologies,setTypologies]= useState<any>([])
  const [search, setSearch] = useState<string|number>('')
  const [dataUsage, setDataUsage] = useState<any>()
  const [dataUsageMonth, setDataUsageMonth] = useState<any>()
  const [dataUsageElastic, setDataUsageElastic] = useState<any>()
  const [dataUsageTemporal, setDataUsageTemporal] = useState<any>([])
  const [widthScreen, setWidthScreen] = useState(document.body.clientWidth)
  const [searchNotActive, setSearchNotActive] = useState(true)
  const [matchingData, setMatchingData] = useState<Array<[]>>([])
  const [similarData, setSimilarData] = useState([])
  const [answersForm, setAnwsersForm] = useState([] as any)
  const [indexAnswersForm, setIndexAnswersForm] = useState(0) 
  const [userClustering, setUserClustering] = useState({} as any)
  const carousel = useRef(null) as any
  const label_indetification:CSSProperties ={
    display:'flex',
    flexDirection:"column",
    gap: '0.5rem', 
  }

  window.addEventListener("resize", function () {
    setWidthScreen(document.body.clientWidth)
  });
  useEffect(()=>{
    getLibs(userBrand,brand).then(libs=>{
        setColors(libs?.colors)
        setTypologies(libs?.typologies)
    })
  },[])

  function CustomizedTextX(props:any) {
    const { x, y, payload, index } = props;
    let discountIndex = dataUsage?.filter((item:any)=>item.percentage == reportInfo?.userProfile?.discountPurchaseProbability) || [{percentage:0}]
    let rest = reportInfo?.userProfile?.discountPurchaseProbability % 10
    let value = payload?.value*10+rest
    if (payload && value == discountIndex[0]?.percentage && payload.value !=0) {
        return (
        <text x={dataUsage?.length-1 == index? x-15: x-6 } y={y + 8} width={30} fill="#535353" height={20} fontSize={8} fontWeight={500} viewBox="0 0 1280 1280">
          {(value/100).toLocaleString('default', { style: 'percent', maximumFractionDigits:1 })}
        </text>
      );
    }
    return (
      <text x={dataUsage?.length-1 == index?x-15: x-6} y={y + 8} width={30} fill="#535353" height={20} fontSize={8} fontWeight={500} viewBox="0 0 1280 1280">
        {((index)/10).toLocaleString('default', { style: 'percent', maximumFractionDigits:1 })}
      </text>
    );
  }
  function searchFind(){
    setSearchNotActive(false)
    let dataPurchaseProbability:any = []
    let obj = {}
      setUserBrand(userBrand)
      setMatchingData([])
      setSimilarData([])
      setDataUsage(null) 
      setDataUsageMonth(null)
      setLoading(true)
      setReportInfo({})
      setUserClustering({})
      setIndexAnswersForm(0)
      setAnwsersForm([])
      getPersonalAdvisor(userBrand, search, brand).then((response) => {
      setReportInfo({...response?.data, userName: response?.data?.userName?.toLowerCase()});
      if(response?.data?.userProfile?.discountPurchaseProbability>=0){
        let probability = response?.data?.userProfile?.discountPurchaseProbability || 0
        let rest = probability % 10
        let conditional = probability == 0?9:10
        if(probability == 0){
          obj = {percentage: 101, index: 0} 
          dataPurchaseProbability.push(obj)
        }
        for(let i = 0; i <= conditional; i = i + 1){
          if(((i * 10 ) + rest )== probability){
              obj = { 
                index: probability ==0? i+1:i,
                percentage: probability,
              }
          }else{
              obj = {
                percentage: 0
              }
          }     
          dataPurchaseProbability.push(obj)
        }
        buildMatchingItems(response?.data?.liveSkuIds[0]) 
        buildSimilarItems(response?.data?.liveSkuIds[0]) 
        buildDataMonth(response?.data?.userProfile?.favorableMonth, response?.data?.userProfile?.unfavorableMonth)
        buildDataTemporal(response?.data?.userProfile?.temporalDetails)
        buildDataElastic([
          response?.data?.userProfile?.priceElasticity?.min,
          response?.data?.userProfile?.priceElasticity?.mean,
          response?.data?.userProfile?.priceElasticity?.max
        ])
        setDataUsage(dataPurchaseProbability)
      }
      getAnswersForms(userBrand,search).then(response=>{
        const check = response?.answers?.forEach((item:any, i: number)=>{
          return item?.answers?.forEach((answer:any, j:number)=>{
            if(answer?.questionAlternatives[0]?.answerDescription == null){
              response?.answers[i]?.answers?.splice(j,1)
            }
          })
        })
        setAnwsersForm(response?.answers)
      })
      getClustering(userBrand,search).then(response=>{
        getQuiz(userBrand, response?.userKey).then(response=>{
          setUserClustering(response)
        })
      }) 
      setLoading(false)
      if(response?.status == 202){
          Toast().fire({
              icon:'success',
              title:'Usuário sendo cadastrado, aguarde e tente novamente',
              background:'#52b303'
          })
      }
      }).catch(error=>{
        console.log(error)
      });
  }
  function buildDataMonth(data:any, unfavorableDate:any = []){
    console.log(data)
    console.log(unfavorableDate)
    let dataMonth:any = []
    let obj = {}
    for(let i = 1; i <= 12; i = i + 1){
      obj = {
          percentage:data[i]*100 ||0,
          unfavorable: (unfavorableDate[i]*100)? (unfavorableDate[i]*100): 0,  
          month:parseMonth(i || 1),
          currency:100, 
      }
      dataMonth.push(obj)
    }
    setDataUsageMonth(dataMonth)
      
  }
  function buildDataTemporal(data:any){

    let dataMonth:any = []
    let obj = {}

      for(let i = 1; i <= 12; i = i + 1){
          let monthLong = new Date(2020,i,0,0,0).toLocaleDateString("pt-br", { month: "long" })
          obj = {
              amount:data[i]?.amount,
              items: data[i]?.items,
              orders: data[i]?.orders,
              monthLong, 
              month:parseMonth(i || 1)   
          }
          dataMonth.push(obj)
      }
      setDataUsageTemporal(dataMonth)
  }
  function buildDataElastic(data:any){
    let dataElastic:any = []
    let obj = {}
    let max = data[2]
    let mean = data[1]
    let min = data[0]

    for(let i = 0; i <=4; i = i + 1){
      obj = {
        amount: 0,
        price: 0,
      }
      if(i == 1){
        obj={
          amount: 0,
          price: min,
          label: "Preço Mínimo"
        }
      }
      if(i === 2){
        obj={
          amount: mean,
          price: mean,
          label: "Preço Médio"
        }
      }
      if(i === 3){
        obj={
          amount: 0,
          price: max,
          label: "Preço Máximo"
        }
      }
      dataElastic.push(obj)
    }
    setDataUsageElastic(dataElastic)
  }

  function HandleBrandName(id:string){
      let brandName = ''
      reportInfo?.userBrands?.map((item:any,index:number)=>{
          if(item?.id == id){
              brandName = item?.brandName
          }
      })

      return brandName
  }
  function handleColorHEX(id:number){
      let currencyColor = colors?.filter((color:any)=>color?.colorId == id)

      return currencyColor[0]
  }
  function handleTypologie(id:number, microTypologieId:number){
      let typologie = typologies[id]?.microTypologies?.filter((typology:any)=>typology?.microTypologyId == microTypologieId)
      let genderName = typologies[id]?.genderName
      if(!typologie?.hasOwnProperty('length')){
        return
      }
      if(!genderName?.hasOwnProperty('length')){
        return
      }
      return {typologie, genderName}
  }
  function showMore(type:string){
  if(type == 'clothing'){
    if(showMoreClothing ==4){
      setshowMoreClothing(999999999) 
    }else{
      setshowMoreClothing(4)
    }
  }
  if(type == 'color'){
    if(showMoreColor ==4){
      setShowMoreColor(999999999)
    }else{
      setShowMoreColor(4)
    }
  }

  }
  function parseMonth(monthNumber?:number){

    if(monthNumber){
      let month:number = monthNumber
      let date = new Date(2020,month,0,0,0)
      if(date?.toLocaleDateString("pt-br", { month:'short'}) == 'Invalid Date'){
          return '-'
      }
      return date?.toLocaleDateString("pt-br", { month:'short'}); 
    }

    if(reportInfo?.userProfile?.favorableMonth || monthNumber){
      let month:number = reportInfo?.userProfile?.favorableMonth || monthNumber
      let date = new Date(2020,month,0,0,0)
      if(date?.toLocaleDateString("pt-br", { month: "long" }) == 'Invalid Date'){
          return '-'
      }
      return date?.toLocaleDateString("pt-br", { month: "long" });
    }else{
        return '-'
    }
  }
  function buildMatchingItems(skuId:number){
    getMatchingItems(userBrand,skuId).then(response=>{
      const arraySkus: string[] = []
      response?.sets.map((sku:any)=>{
        sku?.map((itemId:any)=> arraySkus.push(itemId?.skuId))
      })
      getClothingItemsForSkudId(userBrand,arraySkus).then(clothings=>{
        const data = clothings?.data?.items.map((item:any)=>{
          return {
            ...item,
             itemOfficialImage:{s200: item?.imageUrl, s400: item?.imageUrl},
             itemHeadline: item?.name,
            }
        })
        let dataFormat=[] as any
        let clothing = data?.filter((item:any)=>  skuId == item?.skuId)
        let matching = data?.filter((item:any)=> skuId != item?.skuId)   
        if(clothing?.length == 0){
          return setMatchingData([])
        }
        dataFormat= [[clothing[0] || [], matching[0] || []], [clothing[0]|| [], matching[1]||[]]]  
        setMatchingData(dataFormat) 
      })
    }).catch(error=>{
        
    })
  }
  function buildSimilarItems(skudId:number){ 
 
    getSimilarItems(userBrand,skudId).then(response=>{
      const arraySkus: string[] = []
      response?.items.map((sku:any)=> arraySkus.push(sku?.skuId))
      getClothingItemsForSkudId(userBrand,arraySkus).then(clothings=>{
        const data = clothings?.data?.items.map((item:any)=>{
          return {
            ...item,
             itemOfficialImage:{s200: item?.imageUrl, s400: item?.imageUrl},
             itemHeadline: item?.name,
            }
        })
        setSimilarData(data)
      })
    }).catch(error=>{
        
    })
  }
  function LabelImage({index, styles={}}:any){
    if(index === 0 ){
      return <div style={styles} className={style.labelImage}>Sua peça</div>
    }
    return <div style={{backgroundColor:"#5fcc06"}} className={style.labelImage}>Sugestão</div>
  }
  function changeAnswersRight(e:any){
    e.preventDefault()
    if(answersForm[0]?.answers?.length-1 == indexAnswersForm){
      return setIndexAnswersForm(0)
    }
    setIndexAnswersForm( indexAnswersForm + 1)
  }
  function changeAnswersLeft(e:any){
    e.preventDefault()
    if(indexAnswersForm == 0){
      return setIndexAnswersForm(answersForm[0]?.answers?.length-1)
    }
    setIndexAnswersForm(indexAnswersForm-1)
  }
  function handleItemsDetails(clothingItem:any){
    getItemDetails(userBrand,clothingItem?.skuId).then(response=>{
      setDataModal({...response, ...clothingItem})
    })

  }

  function LabelCardHistory({itemOrderReturned}:any){
    if(itemOrderReturned){
      return <div style={{backgroundColor:"#f7493c"}}  className={style.labelImage}>Cancelado</div>
    }
    return <></>  
  }
  return (
  
    <div className={styles.container} style={{marginTop:0}}>
        <Navigation title="Genie Personal Advisor"/> 
        <TabNavigation/>
        <HeaderRetailer /> 
         {error && <MessageError error={error}/>}
        <div className={style.containerSearch}>
            <h3 className={style.title}>Genie Personal Advisor</h3>
            <SearchBar placeHolder={"Search for CPF"} search={search} setSearch={setSearch} find={searchFind} />
        </div>
        {reportInfo?.userName?
        <>
        <p className={style.title_responsive}>Credenciais do Atendimento</p>
        <div className={styles.formatDisplayFlex} style={{width:'100%', gap:'1rem', minHeight:"100px"}}>
          <CardIdentification
            logo={userBrand?.brandPhoto || logo}
            color={"#6e6e6e"}
            className={style.styleAccredited}  
            informations={{
              credential:  `Credencial ${userBrand?.brandName.replace(/(^\w{1})|(\s+\w{1})/g, (letra:any) => letra?.toUpperCase())}`,
              nick:userBrand?.userRole,
            }} 
          >
          </CardIdentification>  
          <p className={style.title_responsive}>Perfil do Usuário</p>
          <CardIdentification
            className={style.styleAccreditedUsers}  
            color={"#6e6e6e"}  
            informations={{
              credential: `Cliente ${reportInfo?.userName?.replace(/(^\w{1})|(\s+\w{1})/g, (letra:any) => letra?.toUpperCase())}`,
              nick: 'Cliente',
              timestamp: handleDateFormatOne(reportInfo?.userCreatedAt),
            }} 
          >
     
            <div className={styles.contentColor} style={{width:"100%"}}> 

              <div className={style.BehaviorUser}>

                  <div style={label_indetification}>
                    <h6>Itens</h6>
                    <h4>{reportInfo?.userProfile?.totalItems}</h4>
                  </div>
                  <div style={label_indetification}>
                    <h6 >Cancelados</h6>
                    <h4 style = {{color:'#f7493c'}}>{reportInfo?.userProfile?.totalReturns}</h4>
                  </div>
                  <div style={label_indetification}>
                    <h6>Paleta de Cores</h6>
                    <div>
                        <div style={{display:'flex', gap:'0.4rem'}}>
                              {reportInfo?.userProfile?.favorableColors?.map((item:any,index:number)=>{
                                  if(index > 3){
                              return
                              }
                              return <div className={style.boxColor}
                              style={
                                  {
                                    backgroundColor: handleColorHEX(item)?.colorHex,
                                    height:'12px',
                                    width:'12px',
                                    borderRadius:'0.2rem',
                                    boxShadow:'1px 1px 1px 1px rgba(0,0,0,0.2)'
                                  }
                                }>
                                  
                              </div>
                            })}
                        </div>
                    </div>
                  </div>
              </div>  
            </div>  
          
          </CardIdentification> 
          
          <div className={style.indetificationResposive} style={{width:"100%"}}> 
            <section className={style.indetificationResposive}>
                <div className={style.ContentInformationUser}>
                    <div>
                      <FaUserAlt size={20} color="#272727" />
                        <div>
                          <h1 style={{color:style?.color, textTransform:'capitalize'}}>
                            {reportInfo?.userName}
                          </h1>
                        </div>
                    </div>
                      <div>
                        <HiIdentification size={15} color={"#6e6e6e"} />
                        <h6>Cliente</h6>
                      </div>

                      <div> 
                        <WiTime9 size={15} color={"#6e6e6e"} />
                        <h6>{handleDateFormatOne(reportInfo?.userCreatedAt)}</h6>
                      </div>
                </div>
                  <div className={style.BehaviorUserResponsive}>

                      <div style={label_indetification}>
                        <h4>{reportInfo?.userProfile?.totalItems || '0'}</h4>
                        <h6>Itens</h6>
                      </div>
                      <div style={label_indetification}>
                        <h4 style = {{color:'#f7493c'}}>
                          {reportInfo?.userProfile?.totalReturns || '0'}
                        </h4>  
                        <h6>Cancelados</h6>
                      </div>
                      <div> 
                        <div  style={{ display:'flex', alignItems:"center", flexDirection:"column", justifyContent:"space-between", height:"100%"}}> 
                            <div style={{display:'flex', gap:'0.4rem'}}>
                              {reportInfo?.userProfile?.favorableColors?.map((item:any,index:number)=>{
                                      if(index > 3){
                                  return
                                  }
                                  return <div className={style.boxColor}
                                  style={
                                      {
                                        backgroundColor: handleColorHEX(item)?.colorHex,
                                        height:'12px',
                                        width:'12px',
                                        marginTop:'6px',
                                        borderRadius:'0.2rem',
                                        boxShadow:'1px 1px 1px 1px rgba(0,0,0,0.2)'
                                      }
                                    }>
                                      
                                  </div>
                                })}
                            </div>
                            <h6>Paleta de Cores</h6>
                        </div>
                      </div>
                  </div>  
            </section>
           </div>  
        </div>
        <p className={style.title_responsive}>Comportamento</p>
        <div className={style.insights} style={{display: 'flex', width: '100%', marginTop:'1rem'}}>
            <section  className={style.contentColor}  style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem', backgroundColor:'#5fcc06' }}>
                <h4 style={{ margin: 0,color:'white' }}>Gasto até hoje</h4>
                <div style={{
                    display: 'flex',flexDirection: 'column', height: '20%',
                }}>
                    <h2 style ={{color:'white'}}>

                    {loading ?<span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFF" /></span>
                    :
                            reportInfo?.userProfile?.spendUntilNow?(reportInfo?.userProfile?.spendUntilNow/100)?.toLocaleString('default', {style:'currency', currency:"BRL"})
                        :'R$0,00'}
                    </h2> 
                    </div>
            </section>
            <div className={style.insightsDiv}>
              <section  className={style.contentColor} style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem', backgroundColor:'#5fcc06' }}>
                  <h4 style={{ margin: 0,color:'white' }}>Tíquete Médio Típico</h4>
                  <div style={{display: 'flex', flexDirection: 'column', height: '20%', }}>
                      <h2 style ={{color:'white'}}>
                      {loading ?<span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFF" /></span>
                      :
                          reportInfo?.userProfile?.meanTicket?(reportInfo?.userProfile?.meanTicket/100)?.toLocaleString('default', {style:'currency', currency:"BRL"})
                          :'-'}
                      </h2>
                      </div>
              </section>
              

              <section className={style.contentColor} style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem', backgroundColor:'#5fcc06' }}>
                  <h4 style={{ margin: 0,color:'white' }}>PPA</h4>
                  <div style={{
                      display: 'flex', flexDirection: 'column', height: '100%',
                  }}>
                      <h2 style ={{color:'white'}}>{loading?<span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFF" /></span>
                      :reportInfo?.userProfile?.PPA?.toFixed(2) || '-'}</h2>
                  </div>
              </section>
            </div>
        </div> 

        <div className={style.insights2} style={{display: 'flex', width: '100%'}}>
            <div>
              <section style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem' }}>
                  <h4 style={{ margin: 0}}>Pedidos</h4>
                  <div style={{
                      display: 'flex', marginLeft: 'auto', flexDirection: 'column', alignItems: 'flex-end', height: '100%',
                  }}>
                      <h2>
                          {loading?'':reportInfo?.userProfile?.totalOrders}
                      </h2>
                      {loading && <span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFCC00" /></span>}
                  </div>
              </section>
            
              <section style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem' }}>
                  <h4 style={{ margin: 0}}>Total de Peças</h4>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end', height: '100%', }}>
                      <h2>
                          {loading?'':reportInfo?.userProfile?.totalItems}
                      </h2>
                      {loading && <span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFCC00" /></span>}
                  </div>
              </section>
            </div>
            <div>
              <section style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem' }}>
                  <h4 style={{ margin: 0}}>Cancelados</h4>
                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end', height: '100%',
                  }}
                  >
                      <h2  style = {{color:'#f7493c'}}>
                      {loading && reportInfo?.userProfile?'-':reportInfo?.userProfile?.totalReturns || 0}
                      </h2>
                      {loading && <span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFCC00" /></span>}
                  </div>
              </section>

              <section style={{ justifyContent: 'flex-start', padding: '1rem', gap: '0.5rem' }}>
                  <h4 style={{ margin: 0}}>Pagamento Favorito</h4>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end', height: '100%', }}>
                      <h2>
                          {loading?'':reportInfo?.userProfile?.favorablePaymentMethod == 0? 'Crédito': '-' || '-'}
                      </h2>
                      {loading && <span className={styles.spinner}><BiLoaderAlt size={24} fill="#FFCC00" /></span>}
                  </div>
              </section>
                  
            </div>

        </div>
        <p className={style.title_responsive} style={{marginBottom:'1rem'}}>Análise Estatística</p>
        <div className={styles.formatDisplayFlex} style={{width:'100%', gap:'1rem'}}> 
             
            <section className={style.histogram}>
                <h4>Probabilidade de Compra com Desconto</h4>
            {!loading && reportInfo?
                    <ResponsiveContainer width={"100%"} height={widthScreen < 500 ? 150:250}>
                    <ComposedChart width={600} height={400} data={dataUsage} barSize={10} barGap={0}>
                        <CartesianGrid horizontal={false} vertical={false}/>
                            <XAxis dataKey="index" fontSize={12} tick={<CustomizedTextX/>}/>
                            <Area type='monotone' fill="#ffcc00" stroke="#ffcc00" dataKey="percentage"/>
                            <Tooltip content={<CustomTooltip/>}/> 
                    </ComposedChart>
                </ResponsiveContainer>
            : <div className={styles.contentSection}><BiLoaderAlt size={24}/></div>}

            </section>
             
            <section  className={style.histogram}>
            <h4 style={{textTransform:'capitalize'}}>
                  Histograma de Frequência
                </h4>
            {!loading && reportInfo?
                    <ResponsiveContainer width={"100%"}  height={widthScreen < 500 ? 150:250}>
                    <ComposedChart margin={{left:15}} width={800} height={400} data={dataUsageMonth} barSize={8} barGap={0}>
                        <CartesianGrid horizontal={false} vertical={false}/>
                            <XAxis dataKey="month" fontSize={12} tick={<CustomizedTextXMonth/>}/>
                            <YAxis width={15} dataKey='currency' tick={<CustomizedTextY/>}/>
                            <Bar fill="#ffcc00"dataKey="percentage"/>
                            
                            <Bar fill="#f7493c" dataKey="unfavorable"/>
                            
                            <Tooltip content={<CustomTooltip/>}/>  
                    </ComposedChart>
                </ResponsiveContainer>
            : <div className={styles.contentSection}><BiLoaderAlt size={24}/></div>}
            </section>
        </div>
        <div className={styles.formatDisplayFlex} style={{width:'100%', gap:'1rem'}}> 
          {reportInfo?.userProfile?.priceElasticity?.min != 0 &&
            <section className={style.histogram}>
                <h4>Elasticidade de Preço</h4>
                <GaussIndicator  
                    min={ reportInfo?.userProfile?.priceElasticity?.min}
                    mean={ reportInfo?.userProfile?.priceElasticity?.mean} 
                    max={ reportInfo?.userProfile?.priceElasticity?.max}
                    sigma={reportInfo?.userProfile?.priceElasticity?.standardDeviation}
                    />
             
                {!loading && reportInfo?
                <>
                        <ResponsiveContainer width={"100%"} height={widthScreen < 500 ? 150:250}>
                        <ComposedChart margin={{left:20, bottom:0, top:20, right:20}} width={600} height={400} data={dataUsageElastic} barSize={10} barGap={0}>
                            <CartesianGrid horizontal={false} vertical={false}/>
                                <XAxis dataKey="index" /> 
                                <Area type='monotone' fill="#ffcc00" stroke="#ffcc00" dataKey="amount"/>
                        </ComposedChart>
                    </ResponsiveContainer> 
                {dataUsageElastic?.length >0 &&
                  <div className={style.legendGraphic}>
                    <div>
                      <p>
                        Min
                      </p>
                      <h6>
                        {(dataUsageElastic[1]?.price / 100)?.toLocaleString("default", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                          })}
                      </h6>
                    </div>
                    <div>
                      <p>
                        Med
                      </p>
                      <h6>
                        {(dataUsageElastic[2]?.price / 100)?.toLocaleString("default", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                          })}
                      </h6>
                    </div>
                    <div>
                      <p>
                        Max
                      </p>
                      <h6>
                        {(dataUsageElastic[3]?.price / 100)?.toLocaleString("default", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                          })}
                      </h6>
                    </div>
                    <div>
                      <p>
                        σ
                      </p>
                      <h6>
                        {(reportInfo?.userProfile?.priceElasticity?.standardDeviation/100)?.toLocaleString("default", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 0, 
                          })}
                      </h6>
                    </div>
                  </div>
                }
                </>


                : <div className={styles.contentSection}><BiLoaderAlt size={24}/></div>} 

      
            </section>
          }
          {
            dataUsageTemporal.length >0 && 
            <section  className={style.histogram}>
              <h4 style={{textTransform:'capitalize'}}>
                  Distribuição Temporal
              </h4>
              {!loading && reportInfo?
                      <ResponsiveContainer width={"100%"}  height={widthScreen < 500 ? 150:250}>
                      <ComposedChart margin={{left:20, bottom:20, top:20, right:20}} width={800} height={400} data={dataUsageTemporal} barSize={8} barGap={0}>
                          <CartesianGrid horizontal={false} vertical={false}/>
                              <XAxis dataKey="month" fontSize={12} tick={<CustomizedTextXMonth/>}/>
                              <YAxis width={15} dataKey='amount' tick={<CustomizedTextYTemporal/>}/>
                              <ZAxis type="number" dataKey="items" range={[60, 600]} name="score" unit="km" />
                              <Scatter fill="#ffcc00" stroke="#ffcc00" dataKey="amount">
                                <LabelList dataKey="items" fontSize={10} fontFamily={"Lato"}/> 
                              </Scatter> 
                              <Tooltip content={<CustomTooltipElastic/>}/>  
                      </ComposedChart>
                  </ResponsiveContainer>
              : <div className={styles.contentSection}><BiLoaderAlt size={24}/></div>}
            </section>
          }
        </div>
        <div className={style.insights3 + " " + style.hidenSection} style={{width:'100%'}}>
          {reportInfo?.userProfile?.favorableColors.length >0 &&
            <section  className={style.contentColor}> 
            <h4 style={{marginBottom:0}}>Cores mais prováveis de aceitação</h4>
                <div>
                      <div className={style.labels} style={{display:'flex', width:"100%"}}>
                          {reportInfo?.userProfile?.favorableColors?.map((item:any,index:number)=>{
                              if(index > 5){
                                  return
                              }
                              return <div className={style.boxColor} style={{backgroundColor: handleColorHEX(item)?.colorHex}}></div>
                          })}

                      </div>
                </div>
            </section>
          }
          
          {reportInfo?.userProfile?.favorableTypologies?.length >0 && 
            <section  className={style.contentColor}>  
            <h4 style={{marginBottom:0}}>Tipologias mais aceitáveis</h4> 
                <div>
                    
                    <div className={style.labels} style={{display:'flex', width:"100%"}}>
                        {reportInfo?.userProfile?.favorableTypologies?.map((item:any,index:number)=>{
                            if(index > 2){
                                return
                            }
                            if(item?.typologyId == 0){
                                return
                            }
                            return <p style={
                                {
                                color:'white',
                                fontWeight:'700',
                                fontSize:'0.7rem',
                                padding:'0.3rem 0.5rem',
                                borderRadius:'0.3rem',
                                backgroundColor:'#FFC000'
                            }}>
                                {handleTypologie(item?.genderId, item?.typologyId)?.typologie[0]?.microTypologyName}
                            </p>
                        })}
                    </div>
                </div> 
            </section>
          }
          {reportInfo?.userProfile?.frequentSizes?.length > 0 &&
            <section  className={style.contentColor}>  
            <h4 style={{marginBottom:0}}>Tamanhos Prováveis</h4>
              <div>
            
                <div  className={style.labels} style={{display:'flex', width:"100%"}}>
                    {reportInfo?.userProfile?.frequentSizes?.map((item:any,index:number)=>{
                        if(index > 4){
                            return
                        } 
                        return <p style={
                            {
                            color:'white',
                            fontWeight:'700',
                            fontSize:'0.8rem',
                            minWidth:'30px',
                            padding:'0.2rem 0.4rem',
                            borderRadius:'0.3rem',
                            textAlign:"center",
                            backgroundColor:'#373737'
                        }}>
                            {item}
                        </p>
                    })}

                </div>
              </div>
            </section>
          }
        </div>

        <div className={style.insights3} style={{width:'100%'}}>
            {reportInfo?.userProfile?.favorableColors.length >0 &&
            <p className={style.title_responsive}>Preferências de Compra</p>
            }
              <div className={style.contentResponsive}> 

                  {reportInfo?.userProfile?.favorableColors.length >0?
                    <section className={style.contentColor}>
                        <div>
                          <h4>Cores mais prováveis de aceitação</h4>
                            <div style={{display:'flex'}} className={style.labels}>
                                {reportInfo?.userProfile?.favorableColors?.map((item:any,index:number)=>{
                                    if(index > 8){
                                        return
                                    }
                                    return <div className={style.boxColor} style={{backgroundColor: handleColorHEX(item)?.colorHex}}></div>
                                })}
        
                            </div>
                        </div>
                    </section>
                    :''
                  } 
                  {reportInfo?.userProfile?.frequentSizes.length >0?
                    <section className={style.contentColor}>
                          <div>
                              {reportInfo?.userProfile?.frequentSizes?.length > 0 && <h4>Tamanhos Prováveis</h4>}
                              
                              <div style={{display:'flex'}} className={style.labels}>
                                  {reportInfo?.userProfile?.frequentSizes?.map((item:any,index:number)=>{
                                      if(index > 5){
                                          return
                                      }
                                      return <p style={
                                          {
                                          color:'white',
                                          fontWeight:'700',
                                          fontSize:'0.8rem',
                                          minWidth:'30px',
                                          textAlign:"center",
                                          padding:'0.2rem 0.4rem',
                                          borderRadius:'0.3rem',
                                          backgroundColor:'#373737'
                                      }}>
                                          {item}
                                      </p>
                                  })}
          
                              </div>
                          </div>
                    </section>
                    :''
                  }
                  {reportInfo?.userProfile?.favorableTypologies.length >0?
                    <section className={ style.contentColor}>
                          <div>
                              <h4>Tipologias mais aceitáveis</h4>
                              <div style={{display:'flex'}} className={style.labels}>
                                  {reportInfo?.userProfile?.favorableTypologies?.map((item:any,index:number)=>{
                                      if(index > 3){
                                          return
                                      }
                                      if(item?.typologyId == 0){
                                          return
                                      }
                                      return <p style={
                                          {
                                          color:'white',
                                          fontWeight:'700',
                                          fontSize:'0.7rem',
                                          padding:'0.3rem 0.5rem',
                                          borderRadius:'0.3rem',
                                          backgroundColor:'#FFC000'
                                      }}>
                                          {handleTypologie(item?.genderId, item?.typologyId)?.typologie[0]?.microTypologyName}
                                      </p>
                                  })}
          
                              </div>
                          </div>
                    </section>
                    :''
                  }
              </div>
        </div>
        {reportInfo?.pastHistory?.length !=0 && 
        <>
          <section className={styles.section1}>
            <h4>Sugestões Similares com Base em Suas Últimas Compras</h4>
            <div className={styles.contentSection}>
              <div className={styles.div_cards}>
                  {!loading?
                similarData?.map((clothingItem:any, index:number)=>{
                    if(index > 4){
                        return
                    } 
                    return (
                      <div>
                        <CardViewTest
                          key={clothingItem?.id}
                          customizeClassName={style.cardPersonalAdvisor} 
                          id={clothingItem?.id}
                          CardProps={clothingItem}
                          imageCardAtual={0}
                          loadingUpdate={''}
                          imageAction={()=>handleItemsDetails(clothingItem)}
                          minWidth={'160px'}
                          labels={<LabelImage index={1}/>}
                          imageSize={clothingItem?.itemOfficialImage?.s400? "s400":"original"}   
                          >
                          <InformationSession  CardProps={clothingItem}>
                            <p style={{color:'#272727'}}>
                              {HandleBrandName(clothingItem?.itemBrandID|| clothingItem?.brandId)} 
                            </p>
                            <p style={{color:'#777777'}}>
                              {clothingItem?.boughtSkuId || clothingItem?.skuId}
                            </p>
                          </InformationSession>
                        </CardViewTest>
                      </div>
                  )
                  }): <BiLoaderAlt size={24}/>}
              </div>
            </div>
          </section>

          <section className={style.matchingSection} >
            <h4>Para Combinar com Uma das Suas Compras</h4>
              <div className={style.matchingSectionResponsive}>
                {!loading?
                matchingData?.map((matching:any, index:number)=>{
                  return (
                    <div className={style.container_matching_card}>
                        {
                          matching?.map((clothingItem:any,index:number)=>{
                            return (
                              <div className={style.container_matching_card}>
                                <CardViewTest
                                  key={clothingItem?.id}
                                  customizeClassName={style.cardPersonalAdvisor} 
                                  id={clothingItem?.id} 
                                  CardProps={clothingItem}
                                  imageCardAtual={0}
                                  imageAction={()=>handleItemsDetails(clothingItem)}
                                  labels={<LabelImage index={index}/>}
                                  loadingUpdate={''}
                                  minWidth={'160px'}
                                  imageSize={"s400"}   
                                  >
                                  <InformationSession  CardProps={clothingItem}>
                                    <p style={{color:'#272727'}}>
                                      {HandleBrandName(clothingItem?.itemBrandID|| clothingItem?.brandId)} 
                                    </p>
                                    <p style={{color:'#777777'}}>
                                      {clothingItem?.boughtSkuId || clothingItem?.skuId}
                                    </p>
                                  </InformationSession>
                                </CardViewTest>
                              </div>
                            ) 
                        })}
                        <div className={style.contentAdd}>  
                          <IoAddSharp color="#202020" size={35}/>
                        </div>
                    </div>
                  )
                  }): <BiLoaderAlt size={24}/>}
              </div>
          </section>
        </>
        }

        <p className={style.title_responsive} style={{marginTop:"1rem"}}>Dados Personalizados</p>
        {
        answersForm?.length > 0 ?
        
        <section className={style.answeredForms}>
            <h4>Formulários Respondidos</h4>
                <span className={style.leftArrow} onClick={ changeAnswersLeft}> 
                  <img src={ArrowLeft}/>
                </span>
                <span className={style.rightArrow} onClick={changeAnswersRight}>
                  <img src={ArrowRight}/>
                </span>
            <div className={style.container_answered} ref={carousel} style={
                {transform:`translateX(-${indexAnswersForm* 347
                  }px)`
                  }}>
                {answersForm[0]?.answers?.map((item:any, index:number)=>{
                  return <CardForm {...item} cardAtual={indexAnswersForm} index={index}/> 
                })}                   
          </div>
        </section>:""
        }
        {
          userClustering?.hasOwnProperty("imageUrl") && 
        <section className={style.container_quiz}>
          <h4>Retorno do Formulário</h4>
          <div className={style.card_quiz}>
          <img alt={userClustering?.description} src={userClustering?.imageUrl}/>
            <div>
              {userClustering?.userCluster?.map((item:any)=>{
                return ( 
                  <div>
                      <p> {item?.clusterName}</p>
                      <label>{item?.userPercentage?.toLocaleString("default", {style:"percent"}) || " "}</label>
                  </div>
                ) 
              })}
            </div>
          </div>
        </section>
       
        }
       
        {reportInfo?.pastHistory?.length !=0 && 
        <section className={styles.section1 + " " + styles.history}>
            <div className={styles.headerSection}>
              <h4>Histórico de Compras</h4>
              <div className={style.showMore} >
                {showMoreClothing == 4 ?
                  <IoIosArrowDown size={22}  
                    onClick={()=>{showMore('clothing')}}
                  />:
                  <IoIosArrowUp size={22} onClick={()=>{showMore('clothing')}}/>
                }
                <span onClick={()=>{showMore('clothing')}}>{showMoreClothing == 4 ?'Mostrar mais': 'Mostrar menos'}</span>
              </div>
            </div>

            <div className={styles.contentSection}>
              <div className={styles.div_cards}>
                  {!loading?
                [...reportInfo?.pastHistory, ...reportInfo?.userProfile?.boughtUnprocessedItems]?.map((clothingItem:any, index:number)=>{
                  if(index > showMoreClothing){
                      return
                  }
                  return (
                    <CardViewTest
                      key={clothingItem.id}
                      customizeClassName={style.cardPersonalAdvisor} 
                      id={clothingItem.id}
                      CardProps={clothingItem}
                      imageAction={()=> console.log(clothingItem)}
                      imageCardAtual={0}
                      loadingUpdate={''}
                      labels={<LabelCardHistory itemOrderReturned={clothingItem?.itemOrderReturned}/>}
                      minWidth={'160px'}
                      imageSize={clothingItem?.itemOfficialImage?.s400? "s400":"original"}   
                      >
                      <InformationSession  CardProps={clothingItem}>
                        <p style={{color:'#272727'}}>
                          {HandleBrandName(clothingItem?.itemBrandID|| clothingItem?.brandId)} 
                        </p>
                        <p style={{color:'#777777'}}>
                          {clothingItem?.boughtSkuId || clothingItem?.skuId}
                        </p>
                      </InformationSession>
                    </CardViewTest>
                
                )

                  }): <BiLoaderAlt size={24}/>}
              </div>
            </div>
        </section>
        }
        </>:loading? <span className={styles.spinner}><BiLoaderAlt size={40}/></span>:!searchNotActive?
       <h4 
       className={style.notFound}
       style={{margin:'auto',color:'#8a8a8a', fontWeight:400, height:'250px',display:"flex",alignItems:'center'}}>
        Nenhum cliente encontrado
       </h4>
      : ""
      }
      {dataModal &&<ModalSalesDesktop/>}
    </div>
  );
}
