import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { PrivateRoute } from "./components/privateRoute/PrivateRoute";
import { AuthContextProvider } from "./contexts/AuthContext";
import { Login } from "./Page/login/Login";
import { QueryClientProvider, QueryClient } from "react-query"; 
import { Pendding } from "./Page/pendding/pendding";
import { IncomingContextProvider} from './contexts/IncomingContext'
import { Signup } from "./Page/login/Signup";
import { RecoverPassword } from "./Page/login/recoverPassword";
import { ConfirmRegister } from "./Page/login/ConfirmRegistration";
import { ConfirmPassword } from "./Page/login/ConfirmPassword";
import { PersonalAdvisor } from "./retailerPages/PersonalAdvisor/PersonalAdvisor";
import { WidgetContextProvider } from "./contexts/widgetContext";
import { PersonalAttendances } from "./retailerPages/PersonalAdvisor/personalAttendances";
import { PersonalShopping } from "./retailerPages/PersonalAdvisor/personalShopping";
import { PersonalContextProvider } from "./contexts/personalContext";
import { PersonalResume } from "./retailerPages/PersonalAdvisor/personalResume";
import { PersonalRequests } from "./retailerPages/PersonalAdvisor/personalRequests";
import { PersonalApprovalRequest } from "./retailerPages/PersonalAdvisor/personalApprovalRequest";
import { PersonalRequestDetails } from "./retailerPages/PersonalAdvisor/personalDetails";

const queryClient = new QueryClient();

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
      <IncomingContextProvider>
        <WidgetContextProvider>
          <PersonalContextProvider>
          <BrowserRouter>
            <Routes>

              <Route path="/auth/confirmregistration" element={<ConfirmRegister/>}/>
              <Route path="/auth/confirmPassword" element={<ConfirmPassword/>}/>

              <Route path="/auth/recoverpassword" element={<RecoverPassword/>}/>

              <Route path="/quality/brands" element={<PrivateRoute />}>
                <Route path="/quality/brands" element={<PersonalAttendances />}></Route>
              </Route>
              <Route path="/retailers/personal-advisor" element={<PrivateRoute />}>
                <Route path="/retailers/personal-advisor" element={<PersonalAdvisor />}></Route>
              </Route>
              <Route path="/consultant/personal-advisor" element={<PrivateRoute />}>
                <Route path="/consultant/personal-advisor" element={<PersonalAdvisor />}></Route>
              </Route>
              <Route path="/reitailer/genie-attendances" element={<PrivateRoute />}>
                <Route path="/reitailer/genie-attendances" element={<PersonalAttendances />}></Route>
              </Route>
              <Route path="/reitailer/attendances-shopping" element={<PrivateRoute />}>
                <Route path="/reitailer/attendances-shopping" element={<PersonalShopping />}></Route>
              </Route>
              <Route path="/reitailer/attendances-resume" element={<PrivateRoute />}>
                <Route path="/reitailer/attendances-resume" element={<PersonalResume />}></Route>
              </Route>
              <Route path="/reitailer/attendances-requests" element={<PrivateRoute />}>
                <Route path="/reitailer/attendances-requests" element={<PersonalRequests/>}></Route> 
              </Route>
              <Route path="/reitailer/attendances-approval" element={<PrivateRoute />}>
                <Route path="/reitailer/attendances-approval" element={<PersonalApprovalRequest/>}></Route> 
              </Route>
              <Route path="/reitailer/attendances-details" element={<PrivateRoute />}>
                <Route path="/reitailer/attendances-details" element={<PersonalRequestDetails/>}></Route> 
              </Route>
              <Route path="/retailers" element={<PrivateRoute />}>
                <Route path="/retailers" element={<Pendding />} />
              </Route>
              <Route path="*" element={<Login />} />

            </Routes>
          </BrowserRouter>
          </PersonalContextProvider>
        
        </WidgetContextProvider>
        </IncomingContextProvider>
      </AuthContextProvider>

    </QueryClientProvider>
  );
}

export default App;
