import { CSSProperties, ReactNode } from "react";
import { IclothingItem } from "../../types/clothingType";
import styles from "./card.module.scss";

interface InformatioProps extends IclothingItem {
  id:string;
  itemHeadline?: string;
  refSku?: string;
  brandName?:string;
  skuId?:string;
  boughtSku?:string;

};

type CardProps = {
  CardProps: IclothingItem & InformatioProps;
  fontSize?:number|string;
  color?:string;
  style?: CSSProperties;
  children?:ReactNode;
}

export function InformationSession({CardProps, fontSize,color,style,children}:CardProps) {
    let {itemHeadline} = CardProps
    let newitemHeadline = ''
    let count = 0
    function handleConsole(){
        count = 0
        if(itemHeadline){
            for(let i = 0; i <  itemHeadline?.length; i++){
                newitemHeadline = newitemHeadline + itemHeadline[i]
                if(itemHeadline[i] == ' '){
                    count = count + 1
                }
                if(count == 7){
                    itemHeadline = newitemHeadline
                    return
                }
            }
        }
    }
    handleConsole()
  return (
    <div className={styles.session2}>
      <p  style={{fontSize:fontSize, color, ...style}}>{itemHeadline? itemHeadline:''}</p>
      <p style={{fontSize:'0.7rem', marginTop:0,marginBottom:2}}>{CardProps?.brandName}</p>
      {children}
    </div>
  );
}
