import styles from "./card.module.scss";
import brokenLink from '../../assets/brokenLink.jpg'
import  { Dispatch, ReactChildren, ReactNode, useContext, useState } from "react";
import { IncomingContext } from "../../contexts/IncomingContext";
import { IclothingItem } from "../../types/clothingType";
type CardProps = {
  CardProps: IclothingItem;
  imageCheckBox?: 'checkbox' | 'info';
  children?: ReactNode;
  checkboxFunction?():void;
  imageAction?(var1?:IclothingItem,var2?:any,var3?:any, var4?:any):void|Function;
  imageCardAtual:number;
  imageSize?:"s200" |"s400" |"s1000" | "s1500" | "original"; 
  setImageCard?:Dispatch<number>;
  info?:string|number;
  loadingUpdate?:any;
  lib?:any;
  checked?: boolean;
  
};

export function ImageSession(
  {
    imageAction,
    CardProps,
    imageCheckBox,
    children,
    info, 
    checkboxFunction,
    checked,
    imageCardAtual,
    imageSize
  }: CardProps){

  function ImageRender(){
    let images;
    if(CardProps.hasOwnProperty("itemAdditionalImages") && CardProps?.itemAdditionalImages){
      images = [CardProps?.itemOfficialImage,...CardProps?.itemAdditionalImages]
    }else{
      images = [CardProps?.itemOfficialImage]
    }
    let image = images[imageCardAtual || 0]?.hasOwnProperty('s200')?images[imageCardAtual || 0]?.[imageSize || "s200"]: brokenLink
    return image 
  }
  function checkBoxImage(){
    switch (imageCheckBox) {
      case"checkbox":
      return (
        <> 
         <input
          id={'imgCheckbox'}
          checked = {checked}
          onChange = {checkboxFunction}
          className={styles.imageCheckBox} type="checkbox"/>
        </>
      )
      case "info":
        return (<>
          <span className={styles.tagNumber}>{info}</span>
        </>)

      default:
        return <></>;
    }
    }
  return (
    <div className={styles.session1}>
      {checkBoxImage()}
      <img alt =" " src={ImageRender()} 
      onClick={()=>imageAction?imageAction({...CardProps,imageCard:imageCardAtual},imageCardAtual): ()=>{}}/>
      <div className={styles.LabelsActive}>
        {children}
      </div>
    </div>
  );
}
