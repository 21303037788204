import styles from "./searchBar.module.scss";
import { AiOutlineSearch } from "react-icons/ai";

interface props {
  search: string | number;
  setSearch(search: number | string): any;
  find(): any;
  placeHolder:string;
}

export function SearchBar(props: props) {
  const { search, setSearch, find, placeHolder } = props;
  return (
    <div className={styles.contentSearchBar}>
      <span>
        <AiOutlineSearch size={20} />
      </span>
      <input
        onKeyPress={(e: any) => {
          if (e.charCode == 13) {
            find();
          }
        }}
        type="text"
        value={search}
        onChange={(event: any) => {
          setSearch(event.target.value);
        }}
        className={styles.searchBar}
        placeholder={placeHolder}
      ></input>
      <button onClick={find}>Find</button>
    </div>
  );
}
