import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { SearchBar } from "../../components/searchBar/searchBar";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";

export function PersonalAttendances() {
  const { userBrand, brand } = useContext(AuthContext);
  const [search, setSearch] = useState<string | number>("");
  const navigate = useNavigate()
  const filter = [
    "P",
    "PP",
    "M",
    "G",
    "GG",
    "XG",
    "34",
    "36",
    "38",
    "42",
    "40",
    "44",
  ];
  function Find() {
    navigate("/reitailer/attendances-shopping")
  }
  return (
    <div className={styles.container}>
      <HeaderRetailer />
      <div className={style.contentSearch}>
        <h4 className={styles.title}>Atendimentos</h4>
        <SearchBar
          find={Find}
          placeHolder="CPF"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className={style.container_filter} >
        <section
          style={{
            paddingTop: "0",
            paddingBottom: "0",
            width: "92%",
            marginRight: "auto",
          }}
        >
          <div className={style.filters}>
            <h3 className={style.subTitle}>Filtros de Tamanho(Opcional)</h3>
            <div>
              {filter.map((item) => {
                return <div className={style.filter}>{item}</div>;
              })}
            </div>
          </div>
        </section>
      </div>

      <div className={style.filters}>
        <h3 className={style.subTitle}>Filtros de Tamanho(Opcional)</h3>
        <div>
          {filter.map((item) => {
            return <div className={style.filter}>{item}</div>;
          })}
        </div>
      </div>

      <button onClick={Find} className={style.buttonMobile}>INICIAR</button>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
