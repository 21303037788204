import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { getnewItems } from "../../routes/getRoutes/getRoutes";
import { IncomingContext } from "../../contexts/IncomingContext";
import { handleDateFormatOne } from "../../utils/handleDateFormat";
import { BiLoaderAlt } from "react-icons/bi";
import {  FaUserAlt } from "react-icons/fa";
import { ItemCart, LastAttendances, PersonalContext } from "../../contexts/personalContext";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";
import { useNavigate } from "react-router-dom";

export function PersonalRequests() {
  const { userBrand, brand } = useContext(AuthContext);
  const { loadMoreFromDatabase } = useContext(IncomingContext);
  const { cart, setCart, lastAttendances, setDetailsAttendances } = useContext(PersonalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMore, setLoadingMore] = useState(false);
  const [EmptyLoadMore, setEmptyLoadMore] = useState(false);
  const [clothings, setClothings] = useState([] as any);
  const [page, setPage] = useState(2);
  const navigate = useNavigate()
  async function loadMore() {
    setLoadingMore(true);
    loadMoreFromDatabase(getnewItems, clothings, page, 50).then(
      (response: any) => {
        setPage(page + 1);
        if (response?.length === 0) {
          setEmptyLoadMore(true);
        }
        setLoadingMore(false);
        setClothings([...clothings, ...response]);
      }
    );
  }
  function handleAttendancesDetails(item: LastAttendances){
    setDetailsAttendances(item)
    navigate("/reitailer/attendances-details")
  }
  

  return (
    <div className={styles.container}>
      <HeaderRetailer />
      <div className={style.contentSearch}>
        <h4 className={styles.title}>Atendimentos</h4>
      </div>
      <main className={style.container_clothings}>
        <div className={style.header_list}>
          <h2>Últimos Atendimentos</h2>
        </div>
        <div className={style.container_attendances}>
            {lastAttendances?.length > 0 && !isLoading?
              lastAttendances?.map(attendances=>{
                  return (
                    <div className={style.card_attendances} onClick={()=> handleAttendancesDetails(attendances)}>
                        <FaUserAlt size={45} color="#676767" />
                      <div>
                        <div>
                          <h3>
                          {attendances?.name} 
                          </h3>
                        </div>  
                        <div>
                          <h6>Total de Itens:</h6>
                          <h5>{attendances?.request?.length}</h5>
                        </div> 

                        <div>
                          <h6>Preço total:</h6>
                          <h5>{(attendances?.price)?.toLocaleString("default",{style:"currency", currency:"BRL"})}</h5>
                        </div> 
                        <div> 
                          <h6>Criado em:</h6>
                          <h5 >{handleDateFormatOne(attendances?.createdAt)}</h5>  
                        </div> 
                      </div>
                      <div className={style.statusAttendance}>PENDENTE</div>
                    </div>
                  )
                })
                :
              <div className={style.notFound}>
              {!isLoading && "Nenhum atendimento encontrado"}
            </div>
          }
        </div>
        
        {isLoading && (
          <span className={styles.loader}>
            <BiLoaderAlt />
          </span>
        )}
      </main>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
