import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./Navigation.module.scss";
interface DropdownProps{
    Svg?:React.ElementType;
    title:string;
    childrens:Array<{title:string,url:string}>
}
export function Dropdown({Svg,title,childrens}:DropdownProps){
  const {setExpandNavigation,dropMenu, setDropMenu} = useContext(AuthContext);
  const [stateIncoming, setStateIncoming] = useState(false);

    function handleShowDropdown() {
      if (dropMenu == title) {
        setExpandNavigation(true);
        setDropMenu('');
      } else {
        setExpandNavigation(true);
        setDropMenu(title);
      }
    }
    function classNameActiveIncoming(state: any) {
      if (state) {
        setStateIncoming(true);
        return stateIncoming;
      }
    }
    function handleMenuDrop() {
      if (dropMenu != title) {
        setDropMenu(true); 
      } else {
        return;
      }
    }

    return(
      <> 
        <div className={styles.contentDrop} id="nav">
        <div
        id="nav"
          onClick={handleShowDropdown}
          className={stateIncoming ? styles.activeLink : ""}
        >
            {Svg && <Svg size={"21"} />}
          <p id="nav">{title}</p>
        </div >

        <div id="nav" className={dropMenu == title ? styles.dropdownLinks : styles.none}>
            {childrens.map((children:any)=>{
                return(
                    <NavLink
                    id="nav"
                    onClick={handleMenuDrop}
                    className={({ isActive }) =>
                      classNameActiveIncoming(isActive) ? styles.activeDropLink : ""
                    }
                    to={children.url}
                    children={
                      <div id="nav">
                        <p id="nav">{children.title}</p> 
                      </div>
                    }
                  />
                )
            })}
        </div>
      </div>
      </>
    )
        

}