import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styles from "./Login.module.scss";
import logo from "../../assets/ctwlk-cloud-logo.png";
import UserPool from "../../services/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Toast } from "../../utils/toast";
export function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [stage, setStage] = useState(1);
  const [code,setCode] = useState('')
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  function getUser(){
    return new CognitoUser({
      Username:email.toLowerCase(),
      Pool: UserPool,
    })
  }

  function sendCode(event: any) {
    event.preventDefault();
    getUser().forgotPassword({
      onFailure: data=>{
        Toast().fire({
          icon: 'error',
          title: 'Erro in request for change your password',
          color: 'white',
          background: '#fa3815',
        });
      },
      onSuccess: data=>{
        Toast().fire({
          icon: 'success',
          title: 'Code sent your email',
          color: 'white',
          background: '#52b303',
        });
      },
      inputVerificationCode: data=>{
        Toast().fire({
          icon: 'success',
          title: 'Code sent your email',
          color: 'white',
          background: '#52b303',
        });
        setStage(2)
      },
    })
  }

  function resetPassword(event:any){

    event.preventDefault()

    if(password !== confirmPassword){
      Toast().fire({
        icon: 'error',
        title: 'Password must be the same as confirm password.',
        color: 'white',
        background: '#fa3815',
      });
      return
    }

    getUser().confirmPassword(code,password,{
      onSuccess: data=>{
        Toast().fire({
          icon: 'success',
          title: 'Password change with success',
          color: 'white',
          background: '#52b303',
        });
        navigate("/")
      },
      onFailure: data=>{
        Toast().fire({
          icon: 'error',
          title: 'Password invalid',
          color: 'white',
          background: '#fa3815',
        });
      },
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_form}>
        <div className={styles.formHeader}>
          <h2>Welcome to Catwalk Cloud</h2>
          <h3>Intelligent. Fast. Powerful.</h3>
          <p>A modular subscription service for better business.</p>
        </div>

        {stage == 1 ? (
          <form onSubmit={sendCode}>
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            ></input>
            <button type="submit">RECOVER PASSWORD</button>
            <div className={styles.backSignin}>
              <span
                onClick={() => {
                  navigate("/");
                }}
              >
                SIGNIN
              </span>
            </div>
          </form>
        ) : (
          <form onSubmit={resetPassword}>
            <input
              placeholder="Code"
              type="text"
              value={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            ></input>
            <input
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            ></input>
            <input
              placeholder="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
            ></input>
            <button type="submit">SEND CONFIRM CODE</button>
            <div className={styles.backSignin}>
              <span
                onClick={() => {
                  navigate("/");
                }}
              >
              </span>
            </div>
          </form>
        )}
      </div>
      <div className={styles.container_logo}>
        <h1>GENIE A.I.</h1>
        <div>
          <img src={logo} alt="ctwlk-cloud-logo"></img>
        </div>
      </div>
    </div>
  );
}
