export interface WidgetProps {
    brandId?:string;
    texts: {
      titles: {
        fontSize?: string;
        fontWeight?: string;
        fontColor?: string; 
      };
      subTitles: {
        fontSize?: string;
        fontWeight?: string;
        fontColor?: string;
      };
      sentences: {
        fontSize?: string;
        fontWeight?: string;
        fontColor?: string;
      };
    };
    pricing: {
      listPrice: {
        fontSize: string;
        fontWeight: string;
        fontColor: string;
        lineThrough: boolean;
      };
      bestPrice: {
        fontSize: string;
        fontWeight: string;
        fontColor: string;
      };
    };
    buttons: {
      buyButtons: {
        borderRadius: string;
        capitalize: boolean;
        fontSize: string;
        fontWeight: string;
        fontColor: string;
        backgroundColor: {
          default: string;
          selected: string;
          disabled: string;
        };
      };
      selectors: {
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        borderColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        fontColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        backgroundColor: {
          default: string;
          selected: string;
          disabled: string;
        };
      };
    };
    carousel: {
      backgroundColor?: string;
      icons?: {
        iconColor?: string;
        backgroundIconColor?: string;
      };
    };
    images: {
      borderRadius?: string;
    };
    misc: {
      desktopBrandArt?:any;
      mobileBrandAvatar?: any;
    };
}

export const WidgetPropsObjectNull = {
  texts: {
    titles: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#000000',
    },
    subTitles: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#000000',
    },
    sentences: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#000000',
    },
  },
  pricing: {
    listPrice: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#000000',
      lineThrough: false,
    },
    bestPrice: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#000000',
    },
  },
  buttons: {
    buyButtons: {
      borderRadius: '0px',
      capitalize: false,
      fontSize: '12px',
      fontWeight: 'normal',
      fontColor: '#FFFFFF',
      backgroundColor: {
        default: '#000000',
        selected:'#FFFFFF',
        disabled: '#a7a7a7',
      },
    },
    selectors: {
      borderRadius: '0px',
      fontSize: '12px',
      fontWeight: 'normal',
      borderColor: {
        default: '#000000',
        selected: '#000000',
        disabled: '#ACACAC',
      },
      fontColor: {
        default: '#FFFFFF',
        selected: '#000000',
        disabled: '#dddddd',
      },
      backgroundColor: {
        default: '#000000',
        selected:'#FFFFFF',
        disabled: '#a7a7a7',
      },
    },
  },
  carousel: {
    backgroundColor: '#FFFFFF',
    icons: {
      iconColor: '#272727',
      backgroundIconColor: '#f5f5f5',
    },
  },
  images: {
    borderRadius: '0px',
  },
  misc: {
    desktopBrandArt: {},
    mobileBrandAvatar: {},
  },

}
interface StyleSheetConfiguration {
  mobile: { // Styles used in the mobile version of the widget
    texts: { // Texts of the widget
      headings: { // Headings of the widget used in each service section
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
        letterSpacing: string;
      };
      titles: { // Titles of the widget used in product modal title and other places
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
      subTitles: { // Subtitles of the widget used in product modal, pre checkout modal, product card, and other places
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
      sentences: { // Sentences of the widget used in product modal for the description of the product
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
    };
    buttons: { // Buttons of the widget
      buyButtons: { // Buy buttons of the widget used in the product modal, pre checkout modal, and in each service section
        borderRadius: string;
        capitalize: boolean;
        letterSpacing: string;
        fontSize: string;
        fontWeight: string;
        fontColor: string;
        backgroundColor: string;
      };
      selectors: { // Selectors of the widget used in the product modal, pre checkout modal for the size selection.
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        borderColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        fontColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        backgroundColor: {
          default: string;
          selected: string;
          disabled: string;
        };
      };
    };
  };
  desktop: { // Styles used in the desktop version of the widget
    texts: { // Texts of the widget
      headings: { // Headings of the widget used in each service section
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
        letterSpacing: string;
      };
      titles: { // Titles of the widget used in product modal title and other places
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
      subTitles: { // Subtitles of the widget used in product modal, pre checkout modal, product card, and other places
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
      sentences: { // Sentences of the widget used in product modal for the description of the product
        fontSize: string;
        fontWeight: string;
        capitalize: boolean;
        fontColor: string;
      };
    };
    buttons: { // Buttons of the widget
      buyButtons: { // Buy buttons of the widget used in the product modal, pre checkout modal, and in each service section
        borderRadius: string;
        capitalize: boolean;
        letterSpacing: string;
        fontSize: string;
        fontWeight: string;
        fontColor: string;
        backgroundColor: string;
      };
      selectors: { // Selectors of the widget used in the product modal, pre checkout modal for the size selection.
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        borderColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        fontColor: {
          default: string;
          selected: string;
          disabled: string;
        };
        backgroundColor: {
          default: string;
          selected: string;
          disabled: string;
        };
      };
    };
  };
  pricing: { // Styles used in the widget for the pricing
    listPrice: { // List price of the widget used in the product modal, pre checkout modal, and in each service section
      fontSize: string;
      fontWeight: string;
      fontColor: string;
      lineThrough: boolean;
    };
    bestPrice: { // Best price of the widget used in the product modal, pre checkout modal, and in each service section
      fontSize: string;
      fontWeight: string;
      fontColor: string;
    };
  };
  carousel: { // Styles used in the widget for the carousel
    backgroundColor: string;
    icons: {
      iconColor: string;
      backgroundIconColor: string;
    };
  };
  images: { // Styles used in the widget for the images
    borderRadius: string;
  };
  misc: { // Miscellaneous styles used in the widget
    desktopBrandArt: {}; // Desktop brand art of the widget used in combine section
    mobileBrandAvatar?: {}; // Mobile brand avatar of the widget used in combine section in mobile
    strictOfficialImage: boolean; // Strict use of official image in the widget, ignoring the cropped or still images
    compositionMobileType: number; // Composition layout type of the widget used in mobile in the combine section
    hideSliderBar: boolean; // Hide slider bar of the widget carousel used in all sections
    combineTitle: string; // Combine title of the widget used in combine section
    sectionGap: string; // Gap between sections of the widget
    combineDescription: string; // Combine description of the widget used in combine section
  };
}