import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import jwtDecode from "jwt-decode";
import UserPool from "../services/UserPool";
import { addPendingUser } from "../routes/updateRoutes/updateRoutes";
import {IuserBrandProps}  from '../types/userBrandType'
import { brandProps } from "../types/brandProps";
type AuthProviderProps = {
  children: ReactNode;
};
interface IAuthenticateProps {
  Username: string;
  Password: string;
}

type UserProps = {
  username: string;
  role: string;
};

interface ContextProps {
  authenticate(credentials: IAuthenticateProps): Promise<unknown>;
  confirmRegistration( username: string, confirmationCode: string,callback: any):any;
  getSession(): any;
  Logout(): any;
  expandNavigation: Boolean;
  setExpandNavigation: Dispatch<SetStateAction<boolean>>;
  user?: UserProps;
  userBrand: brandProps|any;
  setUserBrand: Dispatch<SetStateAction<brandProps>>;
  setUser: any;
  email:{cognitoUserName?:string,userEmail:string,userName?:string};
  setEmail?:any;
  dropMenu: string|boolean;
  setDropMenu: any;
  setBrand: any;
  brand: string; 
  loader: boolean; 
  setLoader: any;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  combination:{enabled:boolean,id:number}; 
  setCombination:any;
  confirmPassword?:string;
  setConfirmPassword:any;
}

export const AuthContext = createContext({} as ContextProps);

export function AuthContextProvider({ children }: AuthProviderProps) {
  const sessionUser = JSON.parse(localStorage.getItem("accessTokenPortunus") || 'null');
  const profileUser = JSON.parse(localStorage.getItem("profile") || 'null');
  const sessionBrandId = JSON.parse(localStorage.getItem("brandId") || "null");
  const [user, setUser] = useState<UserProps>();
  const [expandNavigation, setExpandNavigation] = useState(false);
  const [dropMenu, setDropMenu] = useState('');
  const [userBrand, setUserBrand] = useState<brandProps | {}>(sessionUser); 
  const [brand, setBrand] = useState(sessionBrandId);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState<any>(profileUser);
  const [confirmPassword, setConfirmPassword] = useState<any>(null)
  const [combination, setCombination] = useState<any>({});
  async function confirmRegistration(
    username: string,
    confirmationCode: string,
    callback: any,
  ) {
    return await new Promise((resolve,reject)=>{
      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });
      user.confirmRegistration(confirmationCode, true, function (err, result) {
        if (err) {
          alert(err)
          reject(err)
        } else {
          addPendingUser(email).then(response=>{
          
          })
          callback()
      
          resolve(result)
        }
      });

    })
  
  }

  async function resendCode(username: string, callback: any) {
    let user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    user.resendConfirmationCode(function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  async function newPassword(newPasswordUser: any, callback: any) {
    let authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: newPasswordUser.username,
      Pool: UserPool,
    };

    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(
          newPasswordUser.password,
          requiredAttributes,
          {
            onSuccess: function (result) {
              callback.cognitoCallback(null, userAttributes);
            },
            onFailure: function (err) {
              callback.cognitoCallback(err, null);
            },
          }
        );
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err, null);
      },
    });
  }

  async function authenticate({ Username, Password }: IAuthenticateProps) {
    
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });


      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });
  

      user.authenticateUser(authDetails, {
        onSuccess: async (data: any) => {
          setLoader(false);
          resolve(data);
          await getSession().then((session) => {});
        },

        onFailure: (error) => {
          console.log(error)
          setLoader(false);

          if (!Username) {
            throw new Error("Email or username required");
          }
          if (!Password) {
            throw new Error("Password required");
          }
          if (!Password && !Username) {
            throw new Error("Password and Username required");
          }
          reject(error);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          if(userAttributes){

            setConfirmPassword(userAttributes?.email)
          }
        },
      });
    });
  }

  async function getSession() {
    const getJWT = JSON.parse(
      localStorage.getItem("accessTokenPortunus") || "{}"
    );
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();

      if (user) {
        user.getSession((err: Error, session: any) => {
          if (err) {
            reject(err);
          } else {
            let obj: any = jwtDecode(session.getIdToken().getJwtToken());
            let profile = {
              username: obj.sub,
              role:
                obj["cognito:groups"][0] == "Testers"
                  ? obj["cognito:groups"][1]
                  : obj["cognito:groups"][0],
            };

            setUser({
              ...profile,
            });
            if (getJWT) {
              setUserBrand(getJWT);
            }
          
            resolve(session);
          }
        });
      }
    });
  }

   function Logout() {
    const user = UserPool.getCurrentUser();

    if (user) {
      user.signOut();
      localStorage.removeItem("accessTokenPortunus");
      setUserBrand({});
      document.location.reload();
    }else{
      localStorage.removeItem("accessTokenPortunus");
      setUserBrand({});
    }
  }
  return (
    <AuthContext.Provider
      value={{
        authenticate,
        getSession,
        Logout,
        user,
        setUser,
        expandNavigation,
        setExpandNavigation,
        dropMenu,
        setDropMenu,
        userBrand,
        setUserBrand,
        brand,
        setBrand,
        loader,
        setLoader,
        error,
        setError,
        confirmRegistration,
        email, setEmail,
        combination, setCombination,
        confirmPassword, setConfirmPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
