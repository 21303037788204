import {IuserBrandProps} from '../../types/userBrandType'
import axios from "../../services/axios";
const { apiTest } = axios; 


interface Iclothing {
  approval?: boolean;
  colorPrediction?: number;
  stillPrediction?: boolean;
  pendingClothing?: boolean;
  huePrediction?: boolean;
  hueId?: number;
  colorId?: number;
  itemsArray?:Array<any>;
  predictionImage? : object
  stillImage? : object
  trainingImageIndex?:any;
  trainingImage?: any;
}

export const ApprovalPendingRequest = async(
  user:IuserBrandProps,
  body: {cognitoUserName:string, userName:string}   
 
 ) => {
   const response = await apiTest
     .patch(`users/approval`, body,{
       headers:{
        Authorization:`Bearer ${user.accessToken}`
       }
     })
     .then((response) => {
       return response.data;
     }).catch((error) => {
      throw (error.response.data.message); 
     });
 
   return response; 
 }

export const DeletePendingRequest = async(
  user:IuserBrandProps,
  cognitoUserName:string,   
 
 ) => {
   const response = await apiTest
     .delete(`/users/pending/${cognitoUserName}`,{
       headers:{
        Authorization:`Bearer ${user.accessToken}`
       }
     })
     .then((response) => {
       return response.data;
     }).catch((error) => {
      throw (error.response.data.message); 
     });
 
   return response; 
 }

export const updateStillImage = async (
  user: IuserBrandProps,
  objectId: string,
  clothing: Iclothing
) => {
  const response = await apiTest
    .patch(`quality/still-image/${objectId}`, clothing, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
      
    });

  return response;
};

export const updateHue = async (
  user: IuserBrandProps,
  objectId: string,
  clothing: Iclothing
) => {
  const response = await apiTest
    .patch(`quality/hue/${objectId}`, clothing, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
        throw new Error(error);

    });
  return response;
};
export const updateColorReview = async (
  user: IuserBrandProps,
  objectId: string,
  clothing: Iclothing
) => {
  const response = await apiTest
    .patch(`quality/color/${objectId}`, clothing, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if(error){
        throw new Error(error.message);
      }
    });

  return response;
};
export const updateFirstTimeReview = async (
  user: IuserBrandProps,
  clothing: Array<any>
) => {
  const response = await apiTest
    .patch(`quality/first-time/`, {itemsArray:clothing}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message == "Unexpected token error.") {
      }
      if(error){
        throw new Error(error.message);
        
      }
    });

  return response;
};
export const updateRevision = async (
  user: IuserBrandProps,
  clothing: Array<any>
) => {
  const response = await apiTest
    .patch(`quality/pending-details`, {itemsArray:clothing}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message == "Unexpected token error.") {
      }
      if(error){
        throw new Error(error.message);
        
      }
    });

  return response;
};
export const SendToRevision= async (
  user: IuserBrandProps,
  brand: string,
  imageUrl: string|number = "",
) => {
  const response = await apiTest
    .patch(`quality/send-to-review/${brand}`,{imageUrl},{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message)
    });
  return response;
};
export const UploadImageCrop = async(
  user: IuserBrandProps,
  clothing: any
) => {
  const response = await apiTest
    .post(`quality-assurance/images/crop`, clothing, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message)
      throw new Error(error);
    });

  return response;
}

export const UploadImage = async(
  user: IuserBrandProps,
  clothing: any
) => {
  const response = await apiTest
    .post(`quality-assurance/images/upload`, clothing, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message)
      throw new Error(error);
    });

  return response;
}
export const updateWidgetDraft = async(
  user: IuserBrandProps,
  brand:string,
  body: any
) => {
  const response = await apiTest
    .patch(`genie-web/widget-draft/${brand}`, {widgetDraft:{...body}}, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message)
      throw new Error(error);
    });

  return response;
}

export const UpdateAddToHidden = async(
  user: IuserBrandProps,
  clothingId: any,
  pendingClothing:boolean,
) => {
  const response = await apiTest
    .patch(`quality/hidden-list/${clothingId}`,{pendingClothing},{
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message == "Unexpected token error.") {
      }
    });

  return response;
}
export const updateWebReviewImage = async(
  user: IuserBrandProps,
  clothingId:any,
  image:any,
  brand:any,
  pendingClothing: any
) => {
  let body = {clothingId: clothingId, image: image, pendingClothing}
  let query = `?collection=${pendingClothing ? 'PENDING' : 'LIVE'}`
  const response = await apiTest
    .patch(`quality/web-image/${clothingId}`, body, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error("");

    });

  return response;
}
export const addPendingUser = async(
 profile:any
) => {
  const response = await apiTest
    .post(`users/request-approval/`, profile)
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateProfileBusiness = async(
 profile:any,
 user:IuserBrandProps,
 brand:string
) => {
  const response = await apiTest
    .patch(`brands/business-info/${brand}`, profile,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateProfileLogo = async(
 user:IuserBrandProps,
 body:any
) => {
  const response = await apiTest
    .patch(`/image/nocrop/BRAND`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateImageLogo = async(
  user: IuserBrandProps,
  brand:any,
  body: any
) => {
  const response = await apiTest
    .patch(`brands/profile/${brand}`, body, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message)
      throw new Error(error);
    });

  return response;
}
export const updateProfileRepresentative = async(
 profile:any,
 user:IuserBrandProps,
 brand:string
) => {
  const response = await apiTest
    .patch(`brands/representative/${brand}`, profile,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateAbilities = async(
 user:IuserBrandProps,
 brand:string,
 body:any,


) => {
  const response = await apiTest
    .patch(`/retailers/genie-ai-abilities/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updatePlugAndWidget = async(
 user:IuserBrandProps,
 brand:string,
 body:any,


) => {
  const response = await apiTest
    .put(`/genie-web/plugin-services/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateWidgetMobile = async(
 user:IuserBrandProps,
 brand:string,
 body:any,


) => {
  const response = await apiTest
    .patch(`genie-talks/widget-config/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const sendMessageSupport = async(
 user:IuserBrandProps,
 brand:string,
 body:any,


) => {
  const response = await apiTest
    .post(`/retailers/tickets`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}

export const updateGenieConfig = async(
 user:IuserBrandProps,
 brand:string,
 body:any,


) => {
  const response = await apiTest
    .patch(`/retailers/genie-ai-config/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}

export const generateCredentials = async(
 user:IuserBrandProps,
 brand:any,
 domain:any,
) => {
  const response = await apiTest
    .put(`genie-web/service-tokens/${brand}`,domain,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const generateCredentialsMobile = async(
 user:IuserBrandProps,
 brand:any,
 body:any,
) => {
  const response = await apiTest
    .post(`connect/mobile-app-tokens/${brand}`,body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const generateCredentialsSecondary = async(
 user:IuserBrandProps,
 brand:any,
 body:any,
) => {
  const response = await apiTest
    .post(`/connect/partner-tokens/${brand}`,body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const generateCredentialCRM = async(
 user:IuserBrandProps,
 brand:any,
 body:any,
) => {
  const response = await apiTest
    .post(`/connect/temporary-token`,body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const deleteCredentialsMobile = async(
 user:IuserBrandProps,
 brand:any,
 id:any,
) => {
  const response = await apiTest
    .delete(`connect/mobile-app-tokens/${brand}`,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      },
      params:{
        token:id
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const deleteCredentialsSecondary = async(
 user:IuserBrandProps,
 brand:any,
 id:any,
) => {
  const response = await apiTest
    .delete(`connect/partner-tokens/${brand}`,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      },
      params:{
        token:id
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const grantAcessPanel = async(
 user:IuserBrandProps,
 brand:any,
 body:any,
) => {
  const response = await apiTest
    .patch(`/account/catwalk-access/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user.accessToken}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}

export const addNewUser = async(
  user:IuserBrandProps,
  brand:string,
  body:any,
 ) => {
   const response = await apiTest
     .post(`/account/brand-users/${brand}`, body,{
       headers:{
        Authorization:`Bearer ${user.accessToken}`
       }
     })
     .then((response) => {
       return response.data;
     }).catch((error) => {
        console.log(error.response)
       throw new Error(error.response.data.message);
     });
 
   return response;
 }
export const deleteUser = async(
  user:IuserBrandProps,
  email:any,
  brand:any,

 ) => {
   const response = await apiTest
     .delete(`/account/brand-users/${brand}`,{
       headers:{
        Authorization:`Bearer ${user.accessToken}`
       },
       params:{
         email
       }
     })
     .then((response) => {
       return response.data;
     }).catch((error) => {
       throw new Error("");
     });
 
   return response;
 }
export const ConfirmPassowordNewUser = async(
 user:IuserBrandProps,
 email:string,
 body:any,


) => {
  const response = await apiTest
    .patch(`/account/change-password`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error(error);
    });

  return response;
}
export const TrainingInfo = async(
 user:IuserBrandProps,
 brand:any,
 body:any,
) => {
  const response = await apiTest
    .post(`/retailers/genie-ai/training-actions/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const uploadCRM = async(
 user:IuserBrandProps,
 body:any,


) => {
const response = await apiTest
    .post(`/connect/integration-crm/upload`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateCRM = async(
 user:IuserBrandProps,
 body:any,
) => {
const response = await apiTest
    .post(`/connect/integration-crm/update`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error("");
    });

  return response;
}
export const updateInventoryIntegration = async(
 user:IuserBrandProps,
 brand:string,
 body:any,
) => {
  const response = await apiTest
    .patch(`/connect/integration-library/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error(error.message);
    });

  return response;
}
export const updateNewWidget = async(
 user:IuserBrandProps,
 brand:string,
 body:any,
) => {
  const response = await apiTest
    .patch(`/genie-web/widget-config/${brand}`, body,{
      headers:{
       Authorization:`Bearer ${user?.accessToken || ''}`
      }
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      throw new Error(error.message);
    });

  return response;
}


