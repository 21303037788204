import Swal from 'sweetalert2';

export function Toast(time?:number,close?:boolean) {
  const Toast = Swal.mixin({
    toast: true,
    position:'top-right',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    cancelButtonColor:"#E5AC05",
    showCancelButton:close || false,  
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    timer: time? time: 3000,
    timerProgressBar: true,
  });
 

  return Toast;
}

export function SwalClose(){
  const Close = Swal.close()
  return Close
}