import styles from './Login.module.scss'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/ctwlk-cloud-logo.png'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { ConfirmPassowordNewUser } from '../../routes/updateRoutes/updateRoutes'
import { MessageError } from '../../components/messageError/MessageError'
export function ConfirmPassword(){
    const [code,setCode] = useState('')
    const [code2,setCode2] = useState('')
    const {confirmPassword: emailUser, userBrand, Logout, setConfirmPassword,setLoader, setError, error}=useContext(AuthContext)
    const navigate = useNavigate()
  async function onSubmit(event:any){
        event.preventDefault()
        ConfirmPassowordNewUser(userBrand,emailUser|| '',{userEmail:emailUser,password:code}).then(response=>{
            setConfirmPassword(null)
            setLoader(false)
            navigate('/')
            Logout()
        }).catch(error=>{
           setError('A sua senha deve ter pelo menos 8 dígitos e conter pelo menos uma letra maiúscula e uma minúscula.') 
        })
    }
    return (
        <div className={styles.container}>
        {error &&  <MessageError error={error} />}         
        <div className={styles.container_form}>
          <div className={styles.formHeader}>
            <h2>You must choose a password to your account first.</h2>
          </div>
  
          <form onSubmit={(onSubmit)}>
          <input
          value={code}
          onChange={(event:any)=>{setCode(event?.target.value)}}
              placeholder="Password"
              type="password"
            ></input>
          <input
          value={code2}
          onChange={(event:any)=>{setCode2(event?.target.value)}}
              placeholder="Confirm Password"
              type="password"
            ></input>
            <button type="submit">CONFIRM</button>
            <div className={styles.backSignin}>
            <span onClick={()=>{navigate('/')}}>SIGNIN</span>
            </div>
           
          </form>
        </div>
        <div className={styles.container_logo}>
          <h1>GENIE A.I.</h1>
          <div>
            <img
              src={logo}
              alt="ctwlk-cloud-logo"
            ></img>
          </div>
        </div>
      </div>
    )
}