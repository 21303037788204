import axios from 'axios'


const apiTest = axios.create({

    baseURL:'https://portunus12.mycatwalk.com/'

})
const apiSaas = axios.create({
    baseURL:'https://api.mycatwalk.com/'
   
})

export default {apiTest,apiSaas}