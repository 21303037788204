import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import { routeManagement } from "../../services/sessionService";
import { BiLoaderAlt } from "react-icons/bi";
import { MessageError } from "../../components/messageError/MessageError";
import logo from "../../assets/ctwlk-cloud-logo.png";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const {
    authenticate,
    getSession,
    setUser,
    user,
    userBrand,
    setUserBrand,
    loader,
    setLoader,
    error,
    setError,
    setBrand,
    confirmPassword,
    Logout
  } = useContext(AuthContext);

  const onSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!password || !email) {
      setError("Password and Username required");
      throw new Error("Password and Username required");
    }
    setLoader(true);
    await authenticate({ Username: email, Password: password })
      .then((data: any) => {
        setLoader(false);
      })
      .catch((error) => {
        if (error) {
          if (error.message == "User is not confirmed.") {
            navigate("/auth/confirmregistration");
          }
          setUser({});
          setError(error.message);
        }
      });
  };
  function handleProfileMilestonesPendencies() {
    if (!userBrand?.profileMilestones?.businessInfo) {
      return false;
    }
    if (!userBrand?.profileMilestones?.representativeInfo) {
      return false;
    }

    return true;
  }
  useEffect(() => {
    getSession().then((session: any) => {});
  }, []);

  useEffect(() => {
    if (userBrand) {
      if (user?.username && user?.role) {
        try {
          routeManagement(user).then((response) => {
            setUserBrand(response?.data);
            setBrand(response?.data.brandId);
            if (user?.role != null) {

              if (user.role.includes("Retailer")) {
                    localStorage.setItem(
                      "brandId",
                      JSON.stringify(response?.data.brandId)
                    );
                    if(response?.data?.servicesEnabled.includes(15)){
                      navigate("/consultant/personal-advisor");
                    }else{
                      setError("Unauthorized user")
                      return setTimeout(()=> Logout(), 2000)
                    }
              } else if (user.role.includes("Pending")) {
                navigate("/auth/approvalpending");
              } else if (user.role.includes("Retailer") && response?.data?.servicesEnabled?.includes(15)){
                navigate("/consultant/personal-advisor");
              } 
              else if (user.role.includes("Consultant")){
                navigate("/consultant/personal-advisor");
              } 
              else if (user.role.includes("QA")){
                navigate("/consultant/personal-advisor");
              }
            }
          });
        } catch (error) {}
      }
    }
  }, [userBrand]);

  useEffect(() => {
    if (confirmPassword) {
      navigate("/auth/confirmPassword");
    }
  }, [confirmPassword]);

  return (
    <div className={styles.container}>
      {error && <MessageError error={error} />}
      <div className={styles.background}></div>
      <div className={styles.container_form}>
        <div className={styles.formHeader}>
          <h2>Welcome to Catwalk Cloud</h2>
          <h3>Intelligent. Fast. Powerful.</h3>
          <p>A modular subscription service for better business.</p>
        </div>

        <form onSubmit={onSubmit}>
          <div className={styles.content_input} style={{ display: "flex" }}>
            <MdEmail size={20} />
            <input
              id="email"
              placeholder="Email"
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            ></input>
          </div>

          <div className={styles.content_input} style={{ display: "flex" }}>
            <RiLockPasswordFill size={20} />
            <input
              id="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
 
          </div>
          <span       
            onClick={() => {
              navigate("/auth/recoverpassword");
            }}
              >FORGOT YOUR PASSWORD ?
          </span> 
          <button id="submit" type="submit">
            <div className={styles.buttonGradient}>
              {loader ? (
                <span className={styles.loader}>
                  <BiLoaderAlt size="24" />
                </span>
              ) : (
                "LOGIN"
              )}
            </div>
          </button>
         
        </form>
        <div className={styles.form_logo}>
          <img src={logo} alt="ctwlk-cloud-logo"></img>
        </div>
      </div>

      <div className={styles.container_logo}>
        <h1>GENIE A.I.</h1>
        <div>
          <img src={logo} alt="ctwlk-cloud-logo"></img>
        </div>
      </div>
    </div>
  );
}
