import { useContext, useState } from "react";
import { WidgetContext } from "../../contexts/widgetContext";
import styles from "./modal.module.scss";
import { IoMdClose } from "react-icons/io";


export function ModalSalesDesktop() {
  const { dataModal, setDataModal, widget } = useContext(WidgetContext);
  const styleSelectorsDefault = {
    ...widget?.buttons?.selectors,
    color:widget?.buttons?.selectors?.fontColor?.default,
    borderColor:widget?.buttons?.selectors?.borderColor?.default,
    backgroundColor: widget?.buttons?.selectors?.backgroundColor?.default,
  }
  const styleTitle= widget?.texts?.titles
  const styleSubtitle= widget?.texts?.subTitles
  const styleBestPrice = {...widget?.pricing?.bestPrice, color:widget?.pricing?.bestPrice?.fontColor}
  const styleListPrice = {...widget?.pricing?.listPrice, color:widget?.pricing?.listPrice?.fontColor, textDecoration:widget?.pricing?.listPrice?.lineThrough?"line-through":""}
  const styleButtonDefault = {...widget?.buttons?.buyButtons, backgroundColor:widget?.buttons?.buyButtons?.backgroundColor?.default }
  const [currentImage, setCurrentImage] = useState(0)
  const priceClothing = dataModal?.skus.filter(clothing=> clothing?.availableQuantity > 0)[0]
  function closeModal(e:any){
      if(e.target.className === styles.container){
        setDataModal(null)
      }else{
        return
      } 
  }


  return (
    <div className={styles.container} onClick={(e:any) => closeModal(e)}>
      <div className={styles.ClothingsWrapper}>
        <div className={styles.modalBodyOne}>
              <div className={styles.wrapperInfoOne}>
              <IoMdClose className={styles.closeModal} size={24} color={"#5a5a5a"} onClick={() => setDataModal(null)} />
                <div className={styles.wrapper_image}>
                  <div className={styles.additional_images}>
                    {dataModal?.additionalImagesUrl?.map((additional:any,index:number)=>{
                        return (
                          <img src={additional?.s200} onClick={()=> setCurrentImage(index)}/>
                        )
                    })}
                 
                  </div>
                  <div className={styles.principalImage}>
                    <img src={dataModal?.additionalImagesUrl[currentImage]?.s1000} />   
                  </div>
                </div>
                <div className={styles.modalInformation}>
                  <div>
                    <h4>{dataModal?.itemHeadline}</h4>
                    <p
                      style={{
                        fontWeight:"700",
                        color:"gray",
                        padding: "1vh 0%",
                      }}
                    >
                      {priceClothing?.bestPriceFormatted? "Por: " + priceClothing?.bestPriceFormatted: " "}
                     
                    </p>
                  </div>

                  <div className={styles.modalInformation_selector}>
                    <div>
                    {priceClothing?.bestPriceFormatted? <h3>Qual o seu tamanho?</h3> : " "}
                      <div>
                        {dataModal?.skus.map(sku=>{
                          return <div style={sku.availableQuantity == 0? {opacity:0.3}: {}}>{sku?.size}</div> 
                        })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.modalInformation_footer}>        
                    <a target="_blank" href={dataModal?.pageUrl}>LINK DO SITE</a>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
