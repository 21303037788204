import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { handleDateFormat } from "../../utils/handleDateFormat";
import { BiLoaderAlt } from "react-icons/bi";
import { ItemCart, PersonalContext } from "../../contexts/personalContext";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";

export function PersonalRequestDetails() {
  const { userBrand, brand } = useContext(AuthContext);
  const { detailsAttendances} = useContext(PersonalContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function Back() {
    navigate("/reitailer/attendances-requests");
  }

  return (
    <div className={styles.container}>
      <HeaderRetailer />
      <div className={style.contentSearch + " " + style.none}>
        <h4 className={styles.title}>Atendimentos</h4>
      </div>
      <main className={style.container_clothings}>
        <div className={style.header_list + " " + style.none}>
          <h3>Detalhes</h3>
          <button 
           onClick={Back}  
          style={{backgroundColor:"#FFCB05", color:"#474747"}}>VOLTAR</button>
        </div>
        {isLoading && (
          <span className={styles.loader}>
            <BiLoaderAlt />
          </span>
        )}
        {detailsAttendances && !isLoading ? (
          <div className={style.clothing_list + " " + style.approval_details}>
            <div className={style.info_request}>
              <div>
                <h3>Nome:</h3>
                <p> John Doo</p> 
              </div>
              <div>
                <h3>CPF:</h3>
                <p> XXXXXXXXX-XX</p>
              </div>
              <div>
                <h3>Endereço:</h3>
                <p> </p>
              </div>
              <div>
                <h3>Contato:</h3>
                <p> (xx) xxxx-xxxx</p>
              </div>
            </div>
              <div  className={style.header_details}>
                  <p>Valor Total:</p>
                  <h3>{(detailsAttendances?.price)?.toLocaleString("default", {style:"currency", currency:"BRL"})}</h3>
              </div>
            <div className={style.clothingFormatColumns}> 
              {detailsAttendances?.request?.map((item: any, key: number) => {
                return (
                  <div className={style.card_request}>
                     <img src={item?.itemOfficialImage?.s400}/>
                    <div className={style.container_request}>
                        <p>{item?.itemHeadline}</p>
                        <h4>{((item?.value * item?.quant)/100)?.toLocaleString("default", {style:"currency", currency:"BRL"})}</h4>
                        <p>Tamanho: M</p>
                        <p>Quantidade: {item?.quant}</p>
                    </div> 
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={style.notFound}>
            {!isLoading && "Detalhes indisponíveis"}
          </div>
        )}
      </main>
      <button className={style.buttonMobile} onClick={Back} style={{color:"#474747"}}> 
        VOLTAR
      </button>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
