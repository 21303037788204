export function handleDateFormat(clothing: any | Array<object>) {
  if (clothing[0]?.timestamp == null) {
    return "";
  }
    let date;
    for (let i = 0; i < 1; i += 1) {
        date = clothing[i]?.timestamp || clothing[i]?.createdAt;
    }

    const data = new Date(date);
    const month = data.toLocaleDateString("eng", { month: "long" });
    let day = data.toLocaleDateString("default", { day: "2-digit" });
    const year = data.getFullYear();
    const DateFormat = `${month}, ${day} - ${year}`;

    if (DateFormat === "Invalid Date, Invalid Date - NaN") {
        return " ";
    }

    return DateFormat;
}
export function handleDateFormatOne(date: any | Array<object>) {
    if (date == null) {
        return "-";
    }
    const data = new Date(date);
    const month = data.toLocaleDateString("eng", { month: "2-digit" });
    let day = data.toLocaleDateString("default", { day: "2-digit" });
    const year = data.getFullYear();
    const DateFormat = `${month}/${day}/${year}`;

    if (DateFormat === "Invalid Date, Invalid Date - NaN") {
        return " ";
    }

    return DateFormat;
}
export function handleDateFormatOneWithFullTime(date: any | Array<object>) {
    if (date == null) {
        return "-";
    }
    const data = new Date(date);
    const month = data.toLocaleDateString("eng", { month: "2-digit" });
    let day = data.toLocaleDateString("default", { day: "2-digit" });
    const year = data.getFullYear();
    let hour: any = data.getHours();
    let minute: any = data.getMinutes();
    let seconds: any = data.getSeconds();
    if (hour < 10) {
        hour = `0${hour}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    const DateFormat = `${month}/${day}/${year} - ${hour}:${minute}:${seconds}`;

    if (DateFormat === "Invalid Date/Invalid Date - NaNh") {
        return "-";
    }

    return DateFormat;
}
export function handleDateFormatOneWithTime(date: any | Array<object>) {
    if (date == null) {
        return "-";
    }
    const data = new Date(date);
    const month = data.toLocaleDateString("eng", { month: "2-digit" });
    let day = data.toLocaleDateString("default", { day: "2-digit" });
    let hour: any = data.getHours();
    if (hour < 10) {
        hour = `0${hour}`;
    }
    const DateFormat = `${month}/${day} - ${hour}h`;

    if (DateFormat === "Invalid Date/Invalid Date - NaNh") {
        return "-";
    }

    return DateFormat;
}
export function handleDateFormatOneNotTime(date: any | Array<object>) {
    if (date == null) {
        return "-";
    }
    const data = new Date(date);
    const month = data.toLocaleDateString("eng", { month: "2-digit" });
    let day = data.toLocaleDateString("default", { day: "2-digit" });
    let hour: any = data.getHours();
    if (hour < 10) {
        hour = `0${hour}`;
    }
    const DateFormat = `${month}/${day}`;

    if (DateFormat === "Invalid Date/Invalid Date - NaNh") {
        return "-";
    }

    return DateFormat;
}
