import { createContext, Dispatch, ReactNode, useState } from "react";
import { IclothingItem } from "../types/clothingType";
import { TImagesUrls } from "../types/imageUrlsType";
import { WidgetProps, WidgetPropsObjectNull } from "../types/widgetProps";

export const WidgetContext = createContext({} as ContextProps);

 interface DataModal extends IclothingItem{
  additionalImagesUrl: TImagesUrls[];
  pageUrl: string;
  skus: [{availableQuantity:number, bestPriceFormatted:number, size:string |number}];
 }

interface ContextProps {
  widget: WidgetProps;
  setWidget: Dispatch<WidgetProps>;
  stepAtual: number;
  setStepAtual: Dispatch<number>;
  preview: "desktop" | "mobile" | "";
  setPreview: Dispatch<"desktop" | "mobile" | "">;
  completedWidget:boolean;
  setCompletedWidget: Dispatch<boolean>;
  dataModal: DataModal |null;
  stepsCompleted:number[],
  setStepsCompleted:Dispatch<number[]>;
  setDataModal: Dispatch<DataModal|null>;
}
interface Props { 
  children: ReactNode;
}
export function WidgetContextProvider({ children }: Props) {
  const [widget, setWidget] = useState<WidgetProps>(WidgetPropsObjectNull);
  const [stepsCompleted,setStepsCompleted] = useState<number[]>([]);
  const [completedWidget, setCompletedWidget] = useState(true);
  const [stepAtual, setStepAtual] = useState(1);
  const [dataModal,setDataModal] = useState<DataModal| null>(null)
  const [preview, setPreview] = useState<"desktop" | "mobile" | "">("desktop");
  return (
    <WidgetContext.Provider 
      value={{
        widget,
        setWidget,
        stepAtual,
        setStepAtual,
        preview,
        setPreview,
        completedWidget,
        setCompletedWidget,
        dataModal,setDataModal,
        stepsCompleted,setStepsCompleted
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
}
