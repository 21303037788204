import { TbNotes } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import style from "./tabNavigation.module.scss";
import { BiSearch } from "react-icons/bi";
import { AiOutlineUnorderedList } from "react-icons/ai";

export function TabNavigation() {
  return (
    <nav className={style.container}>
      <NavLink
        className={({ isActive }) => (isActive ? style.activeLink : "")}
        to={"/consultant/personal-advisor"}
      >
        <BiSearch size={20} />
        <p>Buscar</p>
      </NavLink>
      {/* <NavLink
        className={({ isActive }) => (isActive ? style.activeLink : "")}
        to={"/reitailer/genie-attendances"}
      >
        <TbNotes size={22} />
        <p>Atendimento</p>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? style.activeLink : "")}
        to={"/reitailer/attendances-requests"}
      >
        <AiOutlineUnorderedList size={22} /> 
        <p>Pedidos</p>
      </NavLink> */}
    </nav>
  );
}
