import { createContext, Dispatch, ReactNode, useState } from "react";
import { IclothingItem } from "../types/clothingType";
import { WidgetProps, WidgetPropsObjectNull } from "../types/widgetProps";


export const PersonalContext = createContext({} as ContextProps);
export interface ItemCart{
    id: string;
    skuId: string;
    value: number;
    itemHeadline: string;
    quant: number;
    size: string;
}
interface PersonalProps{
    name: string;
    cpf: string;
    address: string;
    contact: string; 
}
export interface LastAttendances{
  name: string;
  quant: number;
  price: number;
  createdAt: string;
  request: ItemCart[];
}
interface ContextProps {
  lastAttendances: LastAttendances[];
  setLastAttendances: Dispatch<LastAttendances[]>;
  cart: ItemCart[];
  frete: number;
  setFrete: Dispatch<number>;
  setCart: Dispatch<ItemCart[]>
  personalInfo: PersonalProps;
  setPersonalInfo: Dispatch<PersonalProps>;
  detailsAttendances: LastAttendances;
  setDetailsAttendances: Dispatch<LastAttendances>;
}
interface Props { 
  children: ReactNode;
}
export function PersonalContextProvider({ children }: Props) {
  const [cart, setCart] = useState<ItemCart[]>([]);
  const [lastAttendances, setLastAttendances] = useState<LastAttendances[]>([])
  const [detailsAttendances, setDetailsAttendances] = useState({} as any)
  const [personalInfo, setPersonalInfo] = useState<PersonalProps>({} as PersonalProps);
  const [frete, setFrete] = useState(0)
  return (
    <PersonalContext.Provider 
      value={{
        cart,
        setCart,
        personalInfo,
        setPersonalInfo,
        frete,
        setFrete,
        lastAttendances,
        setLastAttendances,
        detailsAttendances, 
        setDetailsAttendances
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
}
