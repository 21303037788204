import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { getnewItems } from "../../routes/getRoutes/getRoutes";
import { IncomingContext } from "../../contexts/IncomingContext";
import { handleDateFormat } from "../../utils/handleDateFormat";
import { BiLoaderAlt } from "react-icons/bi";
import { FaCheckCircle, FaTrashAlt } from "react-icons/fa";
import { ItemCart, PersonalContext } from "../../contexts/personalContext";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";

export function PersonalApprovalRequest() {
  const { userBrand, brand } = useContext(AuthContext);
  const { loadMoreFromDatabase } = useContext(IncomingContext);
  const { cart, setCart, lastAttendances, setLastAttendances } =
    useContext(PersonalContext);
  const [search, setSearch] = useState<string | number>("");
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMore, setLoadingMore] = useState(false);
  const [EmptyLoadMore, setEmptyLoadMore] = useState(false);
  const [clothings, setClothings] = useState([] as any);
  const [page, setPage] = useState(2);
  const navigate = useNavigate();

  function Send() {
    setCart([])
    navigate("/reitailer/genie-attendances");
  }
  function totalPrice(){
    let total = cart.reduce((acc,item)=>{
       return acc + (item.quant * item.value)
     },0)
 
     return (total/100)
   }

  return (
    <div className={styles.container}>
      <HeaderRetailer />
      <div className={style.contentSearch + " " + style.none}>
        <h4 className={styles.title}>Atendimentos</h4>
      </div>
      <main className={style.container_clothings}>
        <div className={style.header_list + " " + style.none}>
          <h3 >Detalhes</h3>
          <button onClick={Send}>NOVO PEDIDO</button>
        </div>
        {isLoading && (
          <span className={styles.loader}>
            <BiLoaderAlt />
          </span>
        )}
        {cart?.length > 0 && !isLoading ? (
          <div className={style.clothing_list + " " + style.approval_details}>
            <div className={style.info_request}>
              <div className={style.approval_icon}>
                <AiFillCheckCircle size={80} color={"#53b304"} />
                <h3>Pedido realizado com sucesso</h3>
              </div>
              <div>
                <h3>Nome:</h3>
                <p> John Doo</p> 
              </div>
              <div>
                <h3>CPF:</h3>
                <p> XXXXXXXXX-XX</p>
              </div>
              <div>
                <h3>Endereço:</h3>
                <p> </p>
              </div>
              <div>
                <h3>Contato:</h3>
                <p> (xx) xxxx-xxxx</p>
              </div>
            </div>
            <div className={style.header_details}>
              <p>Valor Total:</p>
              <h3>{totalPrice()?.toLocaleString("default", {style:"currency", currency:"BRL"})}</h3>
            </div>
            <div className={style.clothingFormatColumns}> 

              {cart?.map((clothing: any, key: number) => {
                return (
                  <div className={style.card_request}>
                     <img src={clothing?.itemOfficialImage?.s400}/>
                    <div className={style.container_request}>
                        <p>{clothing?.itemHeadline}</p>
                        <h4>{((clothing?.value * clothing?.quant)/100)?.toLocaleString("default", {style:"currency", currency:"BRL"})}</h4>
                        <p>Tamanho: M</p>
                        <p>Quantidade: {clothing?.quant}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={style.notFound}>
            {!isLoading && "Nenhuma peça selecionada"}
          </div>
        )}
      </main>
      <button className={style.buttonMobile} onClick={Send} style={{backgroundColor:"#53b304"}}> 
        IR PARA ATENDIMENTOS
      </button>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
