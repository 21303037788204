import { RiLogoutBoxLine } from 'react-icons/ri';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import logo from '../../assets/logo.png';
import { AuthContext } from '../../contexts/AuthContext';

export function HeaderRetailer() {
  const { Logout, setUser, userBrand } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      await Logout();
      setUser({});
    } catch (erro) {
      setUser({});
    }
  };

  return (
    <header>
      <p style={{
        position: 'absolute', right: 100, bottom: -50, fontSize: 10,
      }}
      >  
      </p>

      <div>

        <div className={styles.content_logout}>
          {!userBrand?.profileMilestones?.profile? "":
            <h4
            role="presentation"
            onClick={() => navigate('/retailers/Support')}
            >
          </h4>
          }
         

          <h4>{userBrand?.brandName || 'Quality'}</h4>
          <div style={{display:"flex",flexDirection:"column"}}>
            <img src={userBrand?.brandPhoto || logo} alt="logo-catwalk" />
            <p style={{position:"fixed", right:"5vw",top:"40px", fontSize:'10px', zIndex:-1}}>
              store.1.0.18
            </p>
          </div>
 
        </div>
        <button type="button" className={styles.logout} onClick={handleLogout}>
        LOGOUT
          <RiLogoutBoxLine size={10} />
        </button>
      </div>
    </header>
  );
}
