import { useEffect, useContext, useState } from "react";
import { HeaderRetailer } from "../../components/headerRetailer/HeaderRetailer";
import { Navigation } from "../../components/navigation/Navigation";
import { SearchBar } from "../../components/searchBar/searchBar";
import { RetailerNavigation } from "../../components/retailer_navigation/Retailer_navigation";
import styles from "../../retailerPages/retailer/retailer.module.scss";
import style from "./personalAttendances.module.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { getnewItems } from "../../routes/getRoutes/getRoutes";
import { IclothingItem } from "../../types/clothingType";
import { IncomingContext } from "../../contexts/IncomingContext";
import { handleDateFormat } from "../../utils/handleDateFormat";
import { CardViewTest } from "../../components/cardView copy/card";
import { InformationSession } from "../../components/cardView copy/InformationSession";
import { BiLoaderAlt } from "react-icons/bi";
import { FaCheckCircle, FaTrashAlt } from "react-icons/fa";
import { ItemCart, PersonalContext } from "../../contexts/personalContext";
import { useNavigate } from "react-router-dom";
import { TabNavigation } from "../../components/tabNavigation/TabNavigation";

export function PersonalResume() {
  const { userBrand, brand } = useContext(AuthContext);
  const { loadMoreFromDatabase } = useContext(IncomingContext);
  const { cart, setCart, lastAttendances,setLastAttendances } = useContext(PersonalContext);
  const [search, setSearch] = useState<string | number>("");
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMore, setLoadingMore] = useState(false);
  const [EmptyLoadMore, setEmptyLoadMore] = useState(false);
  const [clothings, setClothings] = useState([] as any);
  const [page, setPage] = useState(2);
  const navigate = useNavigate()
  async function loadMore() {
    setLoadingMore(true);
    loadMoreFromDatabase(getnewItems, clothings, page, 50).then(
      (response: any) => {
        setPage(page + 1);
        if (response?.length === 0) {
          setEmptyLoadMore(true);
        }
        setLoadingMore(false);
        setClothings([...clothings, ...response]);
      }
    );
  }
  function selectItem(newItem: ItemCart) {
    let confirm = cart.filter((item) => item?.id == newItem?.id);
    if (confirm.length > 0) {
      setCart(cart.filter((item) => item?.id !== newItem?.id));
      return;
    }
    setCart([...cart, newItem]);
  }

  function Selected(props: any) {
    let confirm = cart.filter((item) => item?.id == props?.id);
    if (confirm?.length > 0) {
      return <FaCheckCircle color="#53B304" size={20} />;
    }
    return <></>;
  }

  function Send() {
    if(cart?.length == 0) return 
    let request = {
      name: "John Doe",
      quant: 3,
      createdAt: new Date().toISOString(),
      price:  totalPrice(),
      request: [...cart],
    }
    setLastAttendances([...lastAttendances, request])
    navigate("/reitailer/attendances-approval")

  }
  function IncrementQuant(item: ItemCart, quant:number){
    console.log(item)
    let increment = cart.map(items=>{
      if(items.id == item.id){
        return ({...items, quant})
      }
      return items
    })
    setCart(increment)
  }
  function totalPrice(){
   let total = cart.reduce((acc,item)=>{
      return acc + (item.quant * item.value)
    },0)

    return (total/100)
  }
  function deleteItem(Item: ItemCart) {
    setCart(cart.filter((item) => item?.id !== Item?.id));
  }


  return (
    <div className={styles.container}>
      <HeaderRetailer /> 
      <div className={style.contentSearch}>
        <h4 className={styles.title}>Atendimentos</h4>
      </div>
      <main className={style.container_clothings}>
        <div className={style.header_list}>
          <h2>Resumo</h2>
          <button 
           onClick={Send} 
          style={{backgroundColor:"#FFCB05", color:"#474747"}}>FINALIZAR ({totalPrice()?.toLocaleString("default", {style:"currency", currency:"BRL"})})</button>
        </div>
        {cart?.length > 0 && !isLoading?
          <section>
            <div>
                <h3>Nome:</h3>
                <p> John Doo</p>
            </div>
            <div>
                <h3>CPF:</h3>
                <p> XXXXXXXXX-XX</p>
            </div>
            <div>
                <h3>Endereço:</h3>
                <p> </p>
            </div>
            <div>
                <h3>Contato:</h3>
                <p> (xx) xxxx-xxxx</p>
            </div>
        </section>:""
      }
      
        {isLoading && (
          <span className={styles.loader}>
            <BiLoaderAlt />
          </span>
        )}
        {cart?.length > 0 && !isLoading ? (
          <div className={style.clothing_list}>
            <div className={style.clothingFormatColumns }> 
            {cart?.map((clothing: any, key: number) => {
                return (
                    <div className={style.card_request}>
                      <img src={clothing?.itemOfficialImage?.s400}/>
                      <div className={style.container_request}>
                        <p>{clothing?.itemHeadline}</p>
                        <h4>{((clothing?.value * clothing?.quant)/100)?.toLocaleString("default", {style:"currency", currency:"BRL"})}</h4>
                        <div>
                          <select>
                            <option>M</option>
                            <option>G</option>
                            <option>PP</option> 
                            <option>p</option> 
                          </select>
                          <input 
                          onChange={(e:any)=>IncrementQuant(clothing,e.target.value)}
                          type={"number"} 
                          min={1} 
                          defaultValue={clothing?.quant}/>
                          <FaTrashAlt color={"#f52e2e"} onClick={()=>deleteItem(clothing)}/>
                        </div>   
                      </div>
                    </div>
                );
            })}
              
            </div>
          </div>
        ) : (
          <div className={style.notFound}>
            {!isLoading && "Nenhuma peça selecionada"}
          </div>
        )}
      </main>
      <button className={style.buttonMobile} onClick={Send}>
       FINALIZAR ({totalPrice()?.toLocaleString("default", {style:"currency", currency:"BRL"})})
      </button>
      <Navigation title="Genie Atendimentos" />
      <TabNavigation/>
    </div>
  );
}
