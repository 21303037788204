import styles from './Login.module.scss'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/ctwlk-cloud-logo.png'
import { useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
export function ConfirmRegister(){
    const [code,setCode] = useState('')
    const {email, confirmRegistration}=useContext(AuthContext)
    const navigate = useNavigate()
  async function onSubmit(event:any){
        event.preventDefault()
        confirmRegistration(email?.userEmail,code,function(){navigate('/')})
    }
    return (
        <div className={styles.container}>
        <div className={styles.container_form}>
          <div className={styles.formHeader}>
            <h2>Welcome to Catwalk Cloud</h2>
            <h3>Intelligent. Fast. Powerful.</h3>
            <p>A modular subscription service for better business.</p>
          </div>

          <form onSubmit={(onSubmit)}>
          <input
          value={code}
          onChange={(event:any)=>{setCode(event?.target.value)}}
              placeholder="Confirm code"
              type="text"
            ></input>
            <div className={styles.labelInform}>
              <h5>A confirmation code was sent to: {email?.userEmail}</h5>
              
            </div>
            <h5>Enter the code above to confirm.</h5> 
            <button type="submit">CONFIRM</button>
            <div className={styles.backSignin}>
            <span onClick={()=>{navigate('/')}}>SIGNIN</span>
            </div>

          </form>
        </div>
        <div className={styles.container_logo}>
          <h1>GENIE A.I.</h1>
          <div>
            <img
              src={logo}
              alt="ctwlk-cloud-logo"
            ></img>
          </div>
        </div>
      </div>
    )
}
