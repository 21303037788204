import { IuserBrandProps } from './../../types/userBrandType';

import axios from "../../services/axios";

const { apiTest, apiSaas} = axios;

interface dateProps{
    dataFrom:string, 
    dataTo:string
}


export const getLibs = async (user: IuserBrandProps, brand: string,service?:number) => {
    let typologies = await apiTest
    .get(`libraries/lookups?language=en`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
        return response.data
    })

    let colors = await apiTest
    .get(`libraries/colors?lang=en`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        }
    })
    .then((response)=>{
        return response.data
    })

    return {typologies,colors}
}
export const getMatchingItems = async (
  user: IuserBrandProps,
  skuId:number
  ) => {
    let response = await apiSaas
    .get(`advisor/matching/${skuId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": "8yOL8TfXV3iYjwIt9NcS07yzW9ut4fgd",
      },
    })
    .then((response) => {
        return response.data
    })

    return response
}

export const getSimilarItems = async (
  user: IuserBrandProps,
  skuId:number
  ) => {
    let response = await apiSaas
    .get(`advisor/similar/${skuId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": "8yOL8TfXV3iYjwIt9NcS07yzW9ut4fgd",
      },
    })
    .then((response) => {
        return response.data
    })

    return response
}
