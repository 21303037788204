import { ReactNode, useContext, useEffect, useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { IncomingContext } from "../../contexts/IncomingContext";
import { IclothingItem } from "../../types/clothingType";
import styles from "./card.module.scss";
import { CheckBoxSession } from "./CheckboxSession";
import { ImageSession } from "./imageSession";
import { InformationSession } from "./InformationSession";


interface ElementProps extends IclothingItem{
  imageCard:number
}

type CardProps = { 
  id: string;
  customizeClassName?:string;
  CardProps: IclothingItem;
  children?: ReactNode;
  loadingUpdate?: string;
  imageAction?(var1?:any,var2?:any,var3?:any,var4?:any): void|Function;
  imageCheckBox?: "checkbox" | "info";
  imageSize?:"s200" |"s400" |"s1000" | "s1500" | "original"; 
  checkboxFunction?(): void;
  imageCardAtual: number;
  minWidth?: number | string;
  checked?: boolean;
  info?: string | number;
  lib?: any;
  labels?: JSX.Element;
  CheckboxSession?: JSX.Element;
  selectImage?:boolean;
  ActionSession?: React.ElementType<{clothing:ElementProps}>;
};

export function CardViewTest({
  id,
  customizeClassName,
  loadingUpdate,
  children,
  minWidth,
  labels,
  CardProps,
  imageCardAtual,
  checkboxFunction,
  imageCheckBox,
  imageAction,
  imageSize,
  checked,
  selectImage,
  info,
  ActionSession,
}: CardProps) {
  const [imageCard, setImageCard] = useState<number>(imageCardAtual);

  return (
    <div className={customizeClassName +  " " + styles.card} style={{ minWidth: minWidth }} key={id}>
      {loadingUpdate == id ? (
        <span className={styles.loadingUpdate}>
          <BiLoaderAlt size={60} />
        </span>
      ) : (
        ""
      )} 
      <ImageSession
        CardProps={CardProps}
        imageCardAtual={imageCard}
        imageCheckBox={imageCheckBox}
        imageAction={imageAction} 
        checkboxFunction={checkboxFunction}
        checked={checked}
        info={info}
        imageSize={imageSize}
      >
      {labels}
      </ImageSession> 
      {children}
      {(CardProps?.itemOfficialImage?.hasOwnProperty('s200') && selectImage ) &&  (
        <div className={styles.session3}>
          <div className={styles.contentSession3}>
            <div className={styles.contentCheckbox}>
              { CardProps?.itemAdditionalImages && [CardProps?.itemOfficialImage,...CardProps?.itemAdditionalImages].map((img,index:number)=>
                {
                  return(
                    <div key={CardProps.id + img?.s200}>
                      <label>{index}</label>
                      <input type="checkbox" role={String(index)} checked={imageCard == index}  onChange={()=>{
                        setImageCard(index)}} value={imageCard}></input>
                    </div>
                  )
                })} 
            </div>
          </div>
        </div>
      )}
      <div className={styles.session4}>
          {ActionSession && <ActionSession clothing ={{...CardProps, imageCard}} />}
      </div>
    </div>
  );
}
