import { IuserBrandProps } from './../../types/userBrandType';
import axios from "../../services/axios";
const { apiTest, apiSaas } = axios;
interface dateProps{
  dataFrom:string,
  dataTo:string
}

export const getnewItems = async (
  user: IuserBrandProps,
  brand: string,
  searchText: string|number = "",
  lastItemId: string| null = "" ,
  page?: number| null,
  limit?: number | null,
  filterSIndex?:boolean,
) => {
  let query = "";
  if (searchText) {
    query = `?searchText=${searchText}`;

    if (lastItemId) query += `&lastItemId=${lastItemId}`;
  } else {
    if (lastItemId) query += `?&lastItemId=${lastItemId}&limit=${limit}`;
  }
  const response = await apiTest
    .get(`quality/integrated-clothings/${brand}${query}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      params:{
        filterSIndex
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message == "Unexpected token error.") {
      }
    });

  return response;
};






export const getPersonalAdvisor = async (
  user: IuserBrandProps,
  userIdentifier?:number|string,
  brandId?:number|string,
) => {
  const integrationLogs = await apiTest
    .get(`quality-assurance/advisor`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      params:{
        userIdentifier,
        brandId
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });

  return integrationLogs
};

export const getAnswersForms = async (
  user: IuserBrandProps,
  userIdentifier?:number|string,
  brandId?:number|string,
) => {
  const integrationLogs = await apiTest
    .get(`advisor/quiz/answers`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      params:{
        userIdentifier,
        brandId: user?.brandId
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
     throw(error);
    });

  return integrationLogs
};
export const getItemDetails = async (
  user: IuserBrandProps,
  skuId:number,
) => {
  const itemDetails = await apiTest
    .get(`retailers/item-details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      params:{
        skuId
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
     throw(error);
    });

  return itemDetails
};
export const getClustering = async (
  user: IuserBrandProps,
  userIdentifier:number|string,
) => {
  const userKey = await apiSaas
    .get(`connect/v1/incognito`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": "8yOL8TfXV3iYjwIt9NcS07yzW9ut4fgd",
        "x-user-id": userIdentifier,
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => {
     return response.data
    })
    .catch((error) => {
     throw(error);
    });

  return userKey
};
export const getQuiz = async (
  user: IuserBrandProps,
  userKey:string,
  ) => {
    let response = await apiSaas
    .get(`quiz/v1/user/clustering`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": "8yOL8TfXV3iYjwIt9NcS07yzW9ut4fgd",
        "x-user-key": userKey
      },
    })
    .then((response) => {
        return response.data
    })

    return response
}


export const getClothingItemsForSkudId = async (
  user: IuserBrandProps,
  skudIds:string[],
) => {
  const skuIdsFormat = skudIds.join(";")
  const integrationLogs = await apiTest
    .get(`retailers/items`, {
      headers: {
        "Content-Type": "application/json",
        "X-Sku-Ids": skuIdsFormat,
        Authorization: `Bearer ${user.accessToken}`,
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });

  return integrationLogs
};

