export function CustomTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          display:"flex",
          flexDirection:"column",
          gap: "0.3rem",
          border: "1px solid #aaaa",
          backgroundColor: "#ffffff",
          padding: "1rem 0.5rem",
        }}
      >
         <div style={{display:"flex", gap:"0.3rem"}}>
        <p
          style={{ 
            fontSize: "11px",
            fontWeight: "700",
            fontFamily: "Lato, sans-serif",
            color: payload[0]?.color || "#535353",
          }}
        >
          Probabilidade de compra:
        </p>
        <h4
          style={{
            fontSize: "11px",
            marginBottom: "0.5rem",
            fontWeight: "900",
            color: payload[0]?.color || "#535353",
          }}
        >
          {(payload[0]?.value / 100).toLocaleString("default", {
            style: "percent",
            maximumFractionDigits: 1,
          })}
        </h4>
        </div>
        {payload[1] &&
        <div style={{display:"flex", gap:"0.3rem"}}>
        <p
          style={{ 
            fontSize: "11px",
            fontWeight: "700",
            fontFamily: "Lato, sans-serif",
            color: payload[1]?.color || "#535353",
          }}
        >
          Probabilidade de cancelamento:
        </p>
        <h4
          style={{
            fontSize: "11px",
            marginBottom: "0.5rem",
            fontWeight: "900",
            color: payload[1]?.color || "#535353",
          }}
        >
          {(payload[0]?.payload?.unfavorable / 100).toLocaleString("default", {
            style: "percent",
            maximumFractionDigits: 1,
          })}
        </h4>
        </div>
        }
      </div>
    );
  }
  return <></>;
}
export function CustomTooltipElastic({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    if (payload[0]?.value == 0) {
      return <></>;
    }

    return (
      <div
        style={{
          border: "1px solid #aaaa",
          backgroundColor: "#ffffff",
          padding: "1rem 0.5rem",
        }}
      >
        <h4
          style={{
            fontSize: "16px",
            marginBottom: "0.5rem",
            fontWeight: "500",
          }}
        >
          {(payload[0]?.value / 100)?.toLocaleString("default", {
            style: "currency",
            currency: "BRL",
          })}
        </h4>
        <p
          style={{
            fontSize: "11px",
            fontWeight: "900",
            fontFamily: "Lato, sans-serif",
            color: "#535353",
          }}
        >
          {payload[0]?.payload?.items
            ? `Total de itens em ${payload[0]?.payload?.monthLong}: ${payload[0]?.payload?.items}`
            : ""}
        </p>
        <p
          style={{
            fontSize: "11px",
            fontWeight: "900",
            fontFamily: "Lato, sans-serif",
            color: "#535353",
          }}
        >
          {payload[0]?.payload?.items
            ? `Total de pedidos em ${payload[0]?.payload?.monthLong}: ${payload[0]?.payload?.orders}`
            : ""}
        </p>
        <p
          style={{
            fontSize: "11px",
            fontWeight: "900",
            fontFamily: "Lato, sans-serif",
            color: "#535353",
          }}
        >
          {payload[0]?.payload?.label}
        </p>
      </div>
    );
  }
  return <></>;
}
export function CustomizedTextXMonth(props: any) {
  const { x, y, payload, index } = props;
  return (
    <text
      x={x}
      y={y + 8}
      width={30}
      fill="#535353"
      height={20}
      fontSize={9}
      fontWeight={500}
      viewBox="0 0 1280 1280"
    >
      {payload.value}
    </text>
  );
}
export function CustomizedTextY(props: any) {
  const { x, y, payload } = props;

  if (payload) {
    return (
      <text
        x={x - 15}
        y={y}
        width={10}
        fill="#535353"
        height={10}
        fontSize={8}
        fontWeight={500}
        viewBox="0 0 1024 1024"
      >
        {(payload.value / 100).toLocaleString("default", { style: "percent" })}
      </text>
    );
  }
  return (
    <text
      x={x - 10}
      y={y - 10}
      width={10}
      height={10}
      fill="#535353"
      fontSize={8}
      fontWeight={500}
      viewBox="0 0 1024 1024"
    >
      {}
    </text>
  );
}
export function CustomizedTextYTemporal(props: any) {
  const { x, y, payload } = props;

  if (payload) {
    return (
      <text
        x={x - 20}
        y={y}
        width={10}
        fill="#535353"
        height={10}
        fontSize={8}
        fontWeight={500}
        viewBox="0 0 1024 1024"
      >
        {(payload.value / 100).toLocaleString("default", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 0,
        })}
      </text>
    );
  }
  return (
    <text
      x={x - 10}
      y={y - 10}
      width={10}
      height={10}
      fill="#535353"
      fontSize={8}
      fontWeight={500}
      viewBox="0 0 1024 1024"
    >
      {}
    </text>
  );
}
export function CustimizedTableElasticPrice(props: any) {
  const { viewBox, payload } = props;
  const { x, y, width, height } = viewBox;
  if (payload) {
    return (
        <>
            <text
                x={x + width -60}
                y={y}
                width={10}
                fill="#535353"
                height={height}
                fontSize={10}
                fontWeight={900}
                viewBox="0 0 1024 1024"
            >
                Min: {(payload[1]?.price / 100)?.toLocaleString("default", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 2,
                })}
            </text>
            <text
                x={x + width -60}
                y={y+ 30}
                width={10}
                fill="#535353"
                height={height}
                fontSize={10}
                fontWeight={900}
                viewBox="0 0 1024 1024"
            >
                Med: {(payload[2]?.price / 100)?.toLocaleString("default", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 2,
                })}
            </text>
            <text
                x={x + width -60}
                y={y+60}
                width={10}
                fill="#535353"
                height={height}
                fontSize={10}
                fontWeight={900}
                viewBox="0 0 1024 1024"
            >
                Max: {(payload[3]?.price / 100)?.toLocaleString("default", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 2,
                })}
            </text>
            <text
                x={x + width -60}
                y={y+90}
                width={10}
                fill="#535353"
                height={height}
                fontSize={10}
                fontWeight={900}
                viewBox="0 0 1024 1024"
            >
                σ: {(payload[5]/ 100)?.toLocaleString("default", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
                })}
            </text>
        </>
    );
  }
  return (
    <text
      x={x - 10}
      y={y - 10}
      width={10}
      height={10}
      fill="#535353"
      fontSize={8}
      fontWeight={500}
      viewBox="0 0 1024 1024"
    >
      {}
    </text>
  );
}
export function CustimizedDotElasticPrice(props: any) {
  const { viewBox, payload } = props;
  const { x, y, width, height } = viewBox;
  if (payload) {
    return (
      <text
        x={x-15}
        y={y}
        width={10}
        fill="#535353"
        height={height}
        fontSize={10}
        fontWeight={500}
        viewBox="0 0 1024 1024"
      >
        {(payload?.price / 100)?.toLocaleString("default", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 2,
        })}
      </text>
    );
  }
  return (
    <text
      x={x - 10}
      y={y - 10}
      width={10}
      height={10}
      fill="#535353"
      fontSize={8}
      fontWeight={500}
      viewBox="0 0 1024 1024"
    >
      {}
    </text>
  );
}
