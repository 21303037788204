import { NavLink } from "react-router-dom";
import styles from "./Navigation.module.scss";
import logo_ctwlk from "../../assets/ctwlk_portunus_logo.svg";
import { RiFolderUserLine } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { GiHamburgerMenu } from 'react-icons/gi'

import {
  MdImageSearch,
  MdOutlineFiberNew,
  MdOutlineKeyboardBackspace,
} from "react-icons/md";
import {AiOutlineExperiment} from 'react-icons/ai'
import { BiPhotoAlbum, BiSearchAlt, BiTrendingUp } from "react-icons/bi";
import { AiOutlineScan } from "react-icons/ai";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { IncomingContext } from "../../contexts/IncomingContext";
import { Dropdown } from "./Dropdown";
import { IoIosClose } from "react-icons/io";

interface Props{
  title?:string;
}
export function Navigation({title}:Props) {
  const { expandNavigation, setExpandNavigation, setDropMenu, Logout } =
    useContext(AuthContext);


  function handleExpandNavigation() {
    if (expandNavigation) { 
      setExpandNavigation(false);
      setDropMenu("")
    } else {
      setExpandNavigation(true);
    }
  }

  function handleLinkNavigation() {
    if (!expandNavigation) {
      setExpandNavigation(true);
      setDropMenu("");
    } else {
      setDropMenu("");
      return;
    }
  } 
  function handleBack() {
    setDropMenu("");
    setExpandNavigation(false)
  }



  function classNavigation() {
    const ClassName = expandNavigation
      ? `${styles.navigation} ${styles.expandNavigation}`
      : !expandNavigation
      ? `${styles.navigation} ${styles.colapseNavigation}`
      : `${styles.navigation}`;

    return ClassName;
  }

  return (
    <>
      <nav className={styles.menuHamburger}>
        {title && <p>{title}</p>}
        <IoIosClose onClick={Logout} size={24} color={"#3d3d3d"}/>  
      </nav>
      <nav id="contentLinks" className={classNavigation()}>
        <div className={styles.contentLinks}>
          <div className={styles.linkHome}>
            <img
               id="button-home"
              src={logo_ctwlk} 
              alt="logo-catwalk"
              onClick={handleExpandNavigation}
            ></img>
            <NavLink
              to=" "
              children={
                <div>
                  <p>
                    {" "}
                    CATWALK
                    <span>CLOUD</span>
                  </p>
                </div>
              }
            />
          </div>
          <Dropdown
            title="Personal Advisor" 
            Svg={RiFolderUserLine}
            childrens={[
              { title: "Search User", url: "/consultant/personal-advisor" },
              // {title:"Genie Atendimentos", url: "/reitailer/genie-attendances"},
              // {title:"Pedidos Realizados", url: "/reitailer/attendances-requests"},  
            ]}
          />
          <NavLink
            to={"/quality/brands"}
            children={
              <div onClick={handleBack}>
                <MdOutlineKeyboardBackspace size="21" />
                <p>Logout</p> 
              </div>
            }
          />
        </div>
      </nav>
    </>
  );
}
