
import axios from "./axios";

const {apiTest} = axios
interface userProps {
  username?: string|undefined;
  role?: string;
}

export async function routeManagement(user: userProps) {
  let role = user?.role?.toLowerCase()
  if(role === 'retailers'){
    role = 'retailer'
  }
    try{
        const response = await apiTest.post(`auth/${role}`,{username:user.username},
         {headers: 
          {"Content-Type": "application/json"},
        }).then(result=>{
          localStorage.removeItem('accessTokenPortunus')
          localStorage.setItem('accessTokenPortunus',JSON.stringify(result?.data))
          return result
        })
        
        return response
    }catch(error:any){
      throw new Error(error);
    }
 
}
 