import style from "../../retailerPages/PersonalAdvisor/PersonalAdvisor.module.scss";

export function CardForm(props: any) {
  if (!props?.hasOwnProperty("questionAlternatives")) {
    return <></>;
  }
  if (props?.questionAlternatives[0]?.answerDescription == null) {
    return <></>;
  }
  return (
    <div className={style.card_form}> 
      <div className={style.titleForm}>
        <div className={style.questioIdDescrition}>{props?.questionId}.</div>
        {props?.questionTitle}
        <p>
            {props?.questionDescription}
        </p>

      </div>
      <ul>
        {props?.questionAlternatives?.map((alternative: any) => {
          return (
            <li
              className={alternative?.selected ? style.optionSelectedForm : ""}
            >
              {alternative?.answerDescription}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
