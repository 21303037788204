import { useContext } from "react";
import { VscError } from "react-icons/vsc";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./messageError.module.scss";
interface messageProps{
  error:string
}

export function MessageError(props: messageProps) {
  const { setError } = useContext(AuthContext);
  return (
    <div className={styles.container} >
      <section className={styles.contentError} style={{flexDirection:"row"}}>
        <div className={styles.contentImg}>
        <VscError size={38}/>
        </div>

        <div>
          <h3>Ops...</h3>
        <p>{props.error}</p>
        <button
          onClick={() => {
            setError("");
          }}
        >
          OK
        </button>
        </div>
       
      </section>
    </div>
  );
}
